import {
  Address,
  Contact,
  CountryCode,
  Hours,
  Location,
  LocationContext,
  UpdateLocationBody,
} from "@freightsimple/generated-apollo-openapi-client";

import { Alert, Button, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { merge } from "ts-deepmerge";
import { useShipmentsApi } from "../../Apis/Apis";
import Spacer from "../../Spacer";
import { LocationEditor } from "./LocationEditor";
import { TabProps } from "./TabProps";

interface EditLocationButtonProps extends TabProps {
  context: LocationContext;
  showAppointment: boolean;
  adjective: string;
}

export function EditLocationButton(props: EditLocationButtonProps) {
  const shipmentId = props.shipmentData.shipment.shipment.shipmentId!;
  const createShipmentApi = useShipmentsApi();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const shipment = props.shipmentData.shipment.shipment;
  const context = props.context;

  function getLocation() {
    return context === LocationContext.Pickup
      ? shipment.pickupLocation!
      : shipment.deliveryLocation!;
  }

  function getContact() {
    return context === LocationContext.Pickup
      ? shipment.pickupContact!
      : shipment.deliveryContact!;
  }

  function getReference() {
    return context === LocationContext.Pickup
      ? shipment.pickupReferenceNumber!
      : shipment.deliveryReferenceNumber!;
  }

  function getBooth(): string {
    const booth =
      context === LocationContext.Pickup
        ? shipment.pickupBoothNumber
        : shipment.deliveryBoothNumber;

    return booth ?? "";
  }

  function getDeadline(): string | undefined {
    const deadline =
      context === LocationContext.Pickup
        ? shipment.pickupDeadline
        : shipment.deliveryDeadline;
    return deadline;
  }

  const [location, setLocation] = useState(getLocation());
  const [contact, setContact] = useState(getContact());
  const [reference, setReference] = useState(getReference());
  const [booth, setBooth] = useState(getBooth());
  const [saving, setSaving] = useState(false);
  const [deadline, setDeadline] = useState(getDeadline());

  useEffect(
    function () {
      if (isModalVisible) {
        // Reset each time we open the modal
        setLocation(getLocation());
        setContact(getContact());
        setReference(getReference());
        setBooth(getBooth());
        setDeadline(getDeadline());
      }
    },

    [isModalVisible],
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  function emptyAddress(): Address {
    return {
      addressLine: "",
      addressLine2: "",
      city: "",
      postalCode: "",
      countryCode: CountryCode.Ca,
    };
  }

  function emptyHours(): Hours {
    return {
      openFrom: "",
      openUntil: "",
    };
  }

  function emptyLocation(): Location {
    return {
      locationType: "",
      businessName: "",
      address: emptyAddress(),
      hours: emptyHours(),
      latitudeLongitude: { latitude: 0, longitude: 0 },
      accessorials: [],
    };
  }

  function emptyContact(): Contact {
    return {
      phoneNumber: "",
      phoneNumberExtension: "",
      emailAddress: "",
      contactName: "",
    };
  }

  function emptyUpdateLocationBody(): UpdateLocationBody {
    return {
      context: LocationContext.Any,
      location: emptyLocation(),
      contact: emptyContact(),
      reference: "",
      booth: "",
      deadline: undefined,
    };
  }

  function buildUpdateLocationBody(): UpdateLocationBody {
    return merge(emptyUpdateLocationBody(), {
      context,
      location,
      contact: contact || emptyContact(),
      reference,
      booth,
      deadline,
    });
  }

  async function handleOk() {
    setSaving(true);

    try {
      if (
        location.locationType === "DistributionWarehouse" &&
        !location.distributionWarehouseBrand
      ) {
        message.warning(
          "Please add a Distribution Warehouse Brand under the location Type dropdown",
        );
      } else {
        const shipmentApi = await createShipmentApi();

        await shipmentApi.updateLocation({
          shipmentId,
          updateLocationBody: buildUpdateLocationBody(),
        });
        await props.onRefresh();
        setIsModalVisible(false);

        message.success(
          "Location updated! BOL/Pickup pack regenerated. Carrier was NOT notified.",
        );
      }
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setSaving(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title={`Edit ${props.adjective} Location`}
        visible={isModalVisible}
        okText="Save changes"
        onOk={handleOk}
        onCancel={handleCancel}
        width={1450}
        confirmLoading={saving}
      >
        <Alert
          type="warning"
          description="Be very careful with this. Editing will not notify the carrier of
          anything changes. It will generate the bill of lading. If you want to notify the carrier about changes, 'Attach' and use the company view"
        />
        <Spacer height={32} />
        <LocationEditor
          location={location}
          contact={contact}
          reference={reference}
          booth={booth}
          deadline={deadline}
          setDeadline={setDeadline}
          setLocation={setLocation}
          setContact={setContact}
          setReference={setReference}
          setBooth={setBooth}
          showAppointment={props.showAppointment}
          adjective={props.adjective}
        />
      </Modal>
      <Button onClick={showModal}>Edit</Button>
    </>
  );
}
