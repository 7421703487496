import {
  AddQuoteRequest,
  CountryCode,
  CurrencyCode,
  LineItem,
  Location,
} from "@freightsimple/generated-apollo-openapi-client";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Spin,
  Switch,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useCarriersApi, useQuotesApi } from "../../Apis/Apis";
import { CurrencyInput } from "../../Components/CurrencyInput";
import HorizontalStack from "../../Components/HorizontalStack";
import { LinkButton } from "../../Components/LinkButton";
import {
  CarrierServicePair,
  SelectCarrierAndService,
} from "../../Components/SelectCarrierAndService";
import Stack from "../../Components/Stack";
import Spacer from "../../Spacer";
import { CurrencySelector } from "../CurrencySelector";
import { AddLineItemButton } from "./AddLineItemButton";
import { LineItemsTable } from "./LineItemsTable";
import { LocationTypeDropdown } from "./LocationTypeDropdown";
import { QuoteGroupSelector } from "./QuoteGroupSelector";
import { TabProps } from "./TabProps";
import { NumberInput } from "../../Components/NumberInput";

const { Title } = Typography;

interface QuoteLocationEditorProps {
  location: Location;
  setLocation: (location: Location) => void;
  showAppointment: boolean;
  adjective: string;
}

function QuoteLocationEditor(props: QuoteLocationEditorProps) {
  const location = props.location;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const postalCode = props.location.address?.postalCode!;
  const [postalCodeIsDirty, setPostalCodeIsDirty] = useState(false);
  const city = props.location.address?.city;
  const state = props.location.address?.stateOrProvinceCode;
  const country =
    props.location.address?.countryCode === CountryCode.Ca ? "🇨🇦" : "🇺🇸";
  const cityStateCountry = `${country} ${city}, ${state}, ${props.location.address?.countryCode}`;
  const [lookingUpPostalCode, setLookingUpPostalCode] = useState(false);
  const createQuotesApi = useQuotesApi();
  async function setPostalCode(postalCode: string) {
    props.setLocation({
      ...location,
      address: {
        ...location.address,
        postalCode,
      },
    });
    setPostalCodeIsDirty(true);
  }

  async function lookupCityState() {
    if (!postalCodeIsDirty) {
      return;
    }
    setLookingUpPostalCode(true);
    try {
      const quotesApi = await createQuotesApi();
      const response = await quotesApi.lookupPostalCode1({ postalCode });

      props.setLocation({
        ...location,
        address: {
          ...location.address,
          addressLine: "",
          addressLine2: "",
          countryCode: response.countryCode,
          stateOrProvinceCode: response.stateOrProvinceCode,
          city: response.city,
        },
        latitudeLongitude: {
          latitude: response.latitude,
          longitude: response.longitude,
        },
      });
      setPostalCodeIsDirty(true);
    } catch {
      message.error("Postal code lookup failed");
    }
    setLookingUpPostalCode(false);
  }

  const suffix = lookingUpPostalCode ? <Spin size="small" /> : cityStateCountry;

  const inside = props.location.accessorials?.includes("LOGISTICS_INSIDE");
  const liftGate = props.location.accessorials?.includes(
    "LOGISTICS_LIFT_GATE_REQUIRED",
  );
  const appointmentRequired = props.location.accessorials?.includes(
    "SCHEDULING_APPOINTMENT_REQUIRED",
  );

  function onToggle(accessorialCode: string) {
    let updatedAccessorials = [...(props.location.accessorials || [])];

    if (props.location.accessorials?.includes(accessorialCode)) {
      // It's there, so remove it
      updatedAccessorials = updatedAccessorials.filter(
        (a) => a !== accessorialCode,
      );
    } else {
      // It's not there, so add it
      updatedAccessorials.push(accessorialCode);
    }

    props.setLocation({
      ...location,
      accessorials: updatedAccessorials,
    });
  }

  function onToggleLiftGate() {
    onToggle("LOGISTICS_LIFT_GATE_REQUIRED");
  }

  function onToggleInside() {
    onToggle("LOGISTICS_INSIDE");
  }

  function onToggleAppointment() {
    onToggle("SCHEDULING_APPOINTMENT_REQUIRED");
  }

  function setLocationType(type: string) {
    props.setLocation({
      ...location,
      locationType: type,
    });
  }

  function setDistributionWarehouseBrand(brand: string) {
    props.setLocation({
      ...location,
      distributionWarehouseBrand: brand,
    });
  }

  return (
    <Form
      style={{ width: "600px" }}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 8, offset: 1 }}
      layout="horizontal"
      colon={false}
    >
      <Form.Item label="Postal Code">
        <Input
          value={props.location.address?.postalCode}
          onChange={function (e) {
            setPostalCode(e.target.value);
          }}
          onBlur={lookupCityState}
          suffix={suffix}
          style={{ width: "300px" }}
        />
      </Form.Item>

      <Form.Item label="Location Type">
        <LocationTypeDropdown
          locationType={location.locationType}
          distributionWarehouseBrand={location.distributionWarehouseBrand}
          setLocationType={setLocationType}
          setDistributionWarehouseBrand={setDistributionWarehouseBrand}
        />
      </Form.Item>

      <Form.Item label="Accessorials">
        <HorizontalStack align="left">
          <Checkbox
            checked={liftGate}
            onChange={function () {
              onToggleLiftGate();
            }}
          >
            Liftgate
          </Checkbox>
          <Checkbox
            checked={inside}
            onChange={function () {
              onToggleInside();
            }}
          >
            Inside
          </Checkbox>
          {props.showAppointment && (
            <Checkbox
              checked={appointmentRequired}
              onChange={function () {
                onToggleAppointment();
              }}
            >
              Appointment
            </Checkbox>
          )}
        </HorizontalStack>
      </Form.Item>
    </Form>
  );
}

interface AddQuoteButtonProps extends TabProps {
  quoteGroupName?: string;
  carrierServicePair?: CarrierServicePair;
}

export function AddQuoteButton(props: AddQuoteButtonProps) {
  const shipmentId = props.shipmentData.shipment.shipment.shipmentId!;
  const createQuotesApi = useQuotesApi();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pickupLocation, setPickupLocation] = useState(
    props.shipmentData.shipment.shipment.pickupLocation!,
  );
  const [deliveryLocation, setDeliveryLocation] = useState(
    props.shipmentData.shipment.shipment.deliveryLocation!,
  );
  const [lineItems, setLineItems] = useState(
    props.shipmentData.shipment.shipment.lineItems!,
  );
  const [quoting, setQuoting] = useState(false);
  const [quoteGroupName, setQuoteGroupName] = useState(
    props.quoteGroupName ?? "",
  );

  const [selectedCarrier, setSelectedCarrier] = useState<
    undefined | CarrierServicePair
  >(props.carrierServicePair);

  const [carrierPriceOriginalCurrency, setCarrierPriceOriginalCurrency] =
    useState<number>(0);
  const [originalCurrency, setOriginalCurrency] = useState<CurrencyCode>(
    CurrencyCode.Cad,
  );
  const [transitBusinessDays, setTransitBusinessDays] = useState<
    undefined | number
  >();

  const [carrierQuoteReference, setCarrierQuoteReference] = useState("");
  const [customerVisible, setCustomerVisible] = useState(false);
  const [includesTax, setIncludesTax] = useState(false);
  const [allowedCurrencies, setAllowedCurrencies] = useState<CurrencyCode[]>([
    CurrencyCode.Cad,
    CurrencyCode.Usd,
  ]);
  const createCarriersApi = useCarriersApi();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function getFedExTransitTime() {
    const quotesApi = await createQuotesApi();
    const response = await quotesApi.getFedExTransitTime({ shipmentId });
    if (response.transitTime) {
      setTransitBusinessDays(response.transitTime);
      message.success(
        `FedEx Transit Time : ${response.transitTime} business days`,
      );
    } else {
      message.warning("Could not find FedEx transit time");
    }
  }

  async function getTruckloadTransitTime() {
    const quotesApi = await createQuotesApi();
    const response = await quotesApi.getTruckloadTransitTime({ shipmentId });
    if (response.transitTime) {
      setTransitBusinessDays(response.transitTime);
      message.success(
        `Truckload Transit Time : ${response.transitTime} business days`,
      );
    } else {
      message.warning("Could not find Truckload transit time");
    }
  }

  async function getCarrierProperties() {
    if (selectedCarrier === undefined) {
      return;
    }
    const carriersApi = await createCarriersApi();
    const carrierInfo = await carriersApi.getCarrierInfo({
      carrierIdentifier: selectedCarrier.carrierIdentifier,
    });
    setIncludesTax(carrierInfo.carrierPaymentStrategy.chargesTaxesOnInvoices);
    setAllowedCurrencies(
      carrierInfo.carrierPaymentStrategy.allowedCurrencyCodes,
    );
    if (carrierInfo.carrierPaymentStrategy.allowedCurrencyCodes.length > 0) {
      setOriginalCurrency(
        carrierInfo.carrierPaymentStrategy.allowedCurrencyCodes[0],
      );
    }
  }

  useEffect(
    function () {
      getCarrierProperties();
    },

    [selectedCarrier?.carrierIdentifier],
  );

  async function handleOk() {
    if (allowedCurrencies.length < 1) {
      message.warning("Does not have allowed currencies, can not upload");
      return;
    }

    if (selectedCarrier === undefined) {
      message.warning("No carrier selected");
      return;
    }

    if (transitBusinessDays === undefined || transitBusinessDays < 0) {
      message.warning("No transit business days");
      return;
    }

    if (quoteGroupName === "") {
      message.warning("No quote group name");
      return;
    }

    if (carrierPriceOriginalCurrency < 30) {
      message.warning("Price is suspiciously cheap");
      return;
    }

    setQuoting(true);
    try {
      const quotesApi = await createQuotesApi();
      const { carrierIdentifier, serviceIdentifier } = selectedCarrier;

      const request: AddQuoteRequest = {
        addQuoteBody: {
          shipmentId,
          quoteGroupName,
          pickupLocation,
          deliveryLocation,
          lineItems,
          carrierIdentifier,
          serviceIdentifier,
          carrierPriceOriginalCurrency,
          originalCurrency,
          carrierQuoteReference,
          transitBusinessDays,
          customerVisible,
          includesTax,
        },
      };

      await quotesApi.addQuote(request);
      await props.onRefresh();
      setIsModalVisible(false);
      message.success("Quoted added");
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setQuoting(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Add Quote"
        visible={isModalVisible}
        okText="Add quote"
        onOk={handleOk}
        confirmLoading={quoting}
        onCancel={handleCancel}
        width={1450}
        destroyOnClose
      >
        <Title level={5}>General Info</Title>
        <Form
          style={{ width: "1200px" }}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 19, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Quote Group Name">
            <QuoteGroupSelector
              value={quoteGroupName}
              onChange={setQuoteGroupName}
              shipmentData={props.shipmentData}
            />
          </Form.Item>
          <Form.Item label="Carriers" style={{ width: "1200px" }}>
            <SelectCarrierAndService
              selectedCarrierService={selectedCarrier}
              setSelectedCarrierService={setSelectedCarrier}
            />
          </Form.Item>
          <Form.Item label="Carrier Price">
            <CurrencyInput
              amount={carrierPriceOriginalCurrency}
              setAmount={setCarrierPriceOriginalCurrency}
              currency={originalCurrency}
            />
          </Form.Item>
          <Form.Item label="Carrier Currency">
            <CurrencySelector
              currencies={allowedCurrencies}
              currency={originalCurrency}
              setCurrency={setOriginalCurrency}
            />
          </Form.Item>
          <Form.Item label="Transit Business Days">
            <HorizontalStack verticalAlign="middle">
              <NumberInput
                value={transitBusinessDays}
                setValue={setTransitBusinessDays}
              />
              <Spacer width={16} />
              <LinkButton onClick={getFedExTransitTime}>
                Get FedEx Transit Time
              </LinkButton>
              <Spacer width={16} />
              <LinkButton onClick={getTruckloadTransitTime}>
                Get Truckload Transit Time
              </LinkButton>
            </HorizontalStack>
          </Form.Item>
          <Form.Item label="Carrier Quote Reference">
            <Input
              value={carrierQuoteReference}
              onChange={function (e) {
                setCarrierQuoteReference(e.target.value);
              }}
              style={{ width: "300px" }}
            />
          </Form.Item>
          <Form.Item label="Includes Tax?">
            <Switch checked={includesTax} onChange={setIncludesTax} />
          </Form.Item>
          <Form.Item label="Make Customer Visible?">
            <Switch checked={customerVisible} onChange={setCustomerVisible} />
          </Form.Item>
        </Form>
        <Spacer height={32} />
        <HorizontalStack>
          <Stack align="left">
            <Title level={5}>Pickup Location</Title>
            <QuoteLocationEditor
              location={pickupLocation}
              setLocation={setPickupLocation}
              adjective="Pickup"
              showAppointment={false}
            />
          </Stack>
          <Spacer width={64} />
          <Stack align="left">
            <Title level={5}>Delivery Location</Title>
            <QuoteLocationEditor
              location={deliveryLocation}
              setLocation={setDeliveryLocation}
              adjective="Delivery"
              showAppointment={true}
            />
          </Stack>
        </HorizontalStack>
        <Spacer height={32} />
        <Title level={5}>Line Items</Title>
        <LineItemsTable
          lineItems={lineItems}
          editable
          updateLineItems={setLineItems}
        />
        <Spacer height={32} />
        <AddLineItemButton
          addLineItem={function (newLineItem: LineItem) {
            setLineItems((_lineItems) => [..._lineItems, newLineItem]);
          }}
        />
      </Modal>
      <Button onClick={showModal}>👍 Add Quote</Button>
    </>
  );
}
