import {
  ListCompaniesRow,
  QualificationStatus,
  SalesContact,
} from "@freightsimple/generated-apollo-openapi-client";

import { Form, Input, Select, Spin, Switch, Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useCompaniesApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import Colors from "../Components/Colors";
import { ConfirmButtonWithTextArea } from "../Components/ConfirmButtonWithTextArea";
import { CreatedAt } from "../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import { MonthSelect } from "../Components/MonthSelect";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { Panel } from "../Components/Panel";
import Stack from "../Components/Stack";
import { ViewCompanyButton } from "../Components/ViewCompanyButton";
import { ViewJsonButton } from "../Components/ViewJsonButton";
import NonCorporateEmailData from "../GeneratedFixtures/NonCorporateEmailData.json";
import { assertNever } from "../Helpers/assertNever";
import { emojiForShipmentVolume } from "../Helpers/emojiForShipmentVolume";
import { isEmpty } from "../Helpers/isEmpty";
import { countIf, hasDuplicates, sum } from "../Helpers/sum";

import Spacer from "../Spacer";
import { useDebouncedCallback } from "use-debounce";
import { WarningComponent } from "../Components/WarningComponent";
import { removePrefix } from "../Helpers/removePrefix";
import { PeriodFormats } from "./FinanceComponents/generatePeriodInfo";
import { applyDuplicateDomains } from "./ListCompaniesScreen/helpers/applyDuplicateDomains";
import { LocationDropdown } from "./MapScreenComponents/LocationDropdown";
import { CompanySalesCallsSnoozedTag } from "./ViewCompanyScreenComponents/CompanySalesCallsSnoozedTag";
import { CompanyStarredTag } from "./ViewCompanyScreenComponents/CompanyStarredTag";
import { ValuesDropdown } from "./ViewShipmentScreenComponents/ValuesDropdown";
import dayjs from "dayjs";
import { emojiForTemperature } from "../Helpers/emojiForTemperature";
import { isDateInThePast } from "../Helpers/isDateInThePast";
import { isBlank, isNotBlank } from "@freightsimple/shared";
import { useParamState } from "../Hooks/useParamState";

enum CompanyFilterType {
  Unqualified = "Unqualified",
  NeverQuoted = "NeverQuoted",
  Quoted = "Quoted",
  ShippingRookie = "ShippingRookie",
  RegularShipper = "RegularShipper",
  AtRisk = "AtRisk",
  Lost = "Lost",
  NeedsVerification = "NeedsVerification",
  RecentSignup = "RecentSignup",
  SalesCallsSnoozed = "SalesCallsSnoozed",
  OnCredit = "OnCredit",
  NeverCalled = "NeverCalled",
  NoSignificantCalls = "NoSignificantCalls",
  BrokerAccount = "BrokerAccount",
  PersonalAccount = "PersonalAccount",
  BusinessAccount = "CompanyAccount",

  // Problems
  NoSalesContacts = "NoSalesContacts",
  MissingDomain = "MissingDomain",
  DuplicateDomains = "DuplicateDomains",
  SalesContactsWithDuplicateEmails = "SalesContactsWithDuplicateEmails",
  SalesContactsWithDuplicateNames = "SalesContactsWithDuplicateNames",
  SalesContactsWithEmailsNotMatchingDomain = "SalesContactsWithNamesNotMatchingDomain",
  SalesContactEmailOnMultipleCompanies = "SalesContactEmailOnMultipleCompanies",
  DuplicateCompanyNames = "DuplicateCompanyNames",
  MissingAddress = "MissingAddress",
  MissingLatitudeLongitude = "MissingLatitudeLongitude",
  MissingPrimaryContact = "MissingPrimaryContact",
  MissingTemperature = "MissingTemperature",
  MissingFreightVolume = "MissingFreightVolume",
}

function isFilterAProblem(companyFilterType: CompanyFilterType): boolean {
  switch (companyFilterType) {
    case CompanyFilterType.Unqualified:
    case CompanyFilterType.NeverQuoted:
    case CompanyFilterType.Quoted:
    case CompanyFilterType.ShippingRookie:
    case CompanyFilterType.RegularShipper:
    case CompanyFilterType.AtRisk:
    case CompanyFilterType.Lost:
    case CompanyFilterType.NeedsVerification:
    case CompanyFilterType.RecentSignup:
    case CompanyFilterType.SalesCallsSnoozed:
    case CompanyFilterType.OnCredit:
    case CompanyFilterType.NeverCalled:
    case CompanyFilterType.NoSignificantCalls:
    case CompanyFilterType.BrokerAccount:
    case CompanyFilterType.PersonalAccount:
    case CompanyFilterType.BusinessAccount:
      return false;

    case CompanyFilterType.NoSalesContacts:
    case CompanyFilterType.MissingDomain:
    case CompanyFilterType.DuplicateDomains:
    case CompanyFilterType.SalesContactsWithDuplicateEmails:
    case CompanyFilterType.SalesContactsWithDuplicateNames:
    case CompanyFilterType.SalesContactsWithEmailsNotMatchingDomain:
    case CompanyFilterType.SalesContactEmailOnMultipleCompanies:
    case CompanyFilterType.DuplicateCompanyNames:
    case CompanyFilterType.MissingAddress:
    case CompanyFilterType.MissingLatitudeLongitude:
    case CompanyFilterType.MissingPrimaryContact:
    case CompanyFilterType.MissingFreightVolume:
    case CompanyFilterType.MissingTemperature:
      return true;
    default:
      assertNever(companyFilterType);
  }
}

enum TemperatureFilter {
  All = "All",
  Hot = "Hot",
  Warm = "Warm",
  Cold = "Cold",
  Dead = "Dead",
}

function AddCompanyButton() {
  const createCompaniesApi = useCompaniesApi();
  const navigate = useNavigate();

  async function onConfirm(companyName: string) {
    const companiesApi = await createCompaniesApi();

    const response = await companiesApi.createCompany({
      companyName,
    });
    const url = `/view-company?companyId=${response.companyId}`;
    navigate(url);
  }

  return (
    <ConfirmButtonWithTextArea
      onConfirm={onConfirm}
      question="What is the name of the company you want to add?"
      okText="Create Company"
      tooltip="Create a new company"
      placeholder="Company Name"
    >
      Create Company
    </ConfirmButtonWithTextArea>
  );
}

const CellLabel = styled.div`
  color: ${Colors.LightText};
  font-size: 10px;
`;

const CellSubText = styled.div`
  color: ${Colors.LightText};
  font-size: 9px;
`;

function applyUnqualifiedFilter(c: ListCompaniesRow): boolean {
  return c.qualificationStatus === QualificationStatus.Unqualified;
}

function applyNeverQuotedFilter(c: ListCompaniesRow): boolean {
  return (
    c.qualificationStatus === QualificationStatus.Qualified && c.quotesRan === 0
  );
}

function applyQuotedFilter(c: ListCompaniesRow): boolean {
  return (
    c.qualificationStatus === QualificationStatus.Qualified &&
    c.quotesRan > 0 &&
    c.shipmentsBooked === 0
  );
}

function applyShippingRookieFilter(c: ListCompaniesRow): boolean {
  return (
    c.qualificationStatus === QualificationStatus.Qualified &&
    c.shipmentsBooked > 0 &&
    c.shipmentsBooked < 5
  );
}

function applyRegularShipperFilter(c: ListCompaniesRow): boolean {
  return (
    c.qualificationStatus === QualificationStatus.Qualified &&
    c.shipmentsBooked >= 5
  );
}

function applyNeedsVerificationFilter(c: ListCompaniesRow): boolean {
  return c.needsVerification && !c.verificationDenied;
}

function applyRecentSignupsFilter(c: ListCompaniesRow): boolean {
  return (
    c.qualificationStatus === QualificationStatus.Qualified &&
    c.quotesRan > 0 &&
    dayjs(c.createdAt).isAfter(dayjs().subtract(30, "days"))
  );
}

function applySalesCallsSnoozedFilter(c: ListCompaniesRow): boolean {
  return (
    c.snoozeSalesCallsUntil !== undefined &&
    c.snoozeSalesCallsUntil !== null &&
    !isDateInThePast(c.snoozeSalesCallsUntil)
  );
}

function applyOnCreditFilter(c: ListCompaniesRow): boolean {
  return c.paymentTermsDays > 0;
}

function applyNeverCalledFilter(c: ListCompaniesRow): boolean {
  return c.lastCallDate === undefined;
}

function applyNoSignificantCallsFilter(c: ListCompaniesRow): boolean {
  return c.lastSignificantCallDate === undefined;
}

function applyNoSalesContactsFilter(c: ListCompaniesRow): boolean {
  return (
    c.salesContacts.length === 0 &&
    // Ignore FreightSimple Ops
    c.companyId !== "7c99c230-d780-476a-82d7-43f69f77653f"
  );
}

function applyDuplicateCompanyNames(
  companies: ListCompaniesRow[],
  c: ListCompaniesRow,
): boolean {
  return countIf(companies, (cc) => c.companyName === cc.companyName) > 1;
}

function applyMissingAddress(c: ListCompaniesRow): boolean {
  if (
    c.isPersonal ||
    c.isBroker ||
    c.temperature === "☠️ Dead" ||
    c.shipmentFrequency === "SingleShipment"
  ) {
    return false;
  }

  return (
    isBlank(c.billingCity) ||
    isBlank(c.billingState) ||
    isBlank(c.billingAddressLine) ||
    isBlank(c.billingPostalCode) ||
    isBlank(c.billingCountry)
  );
}

function applyMissingLatitudeLongitude(c: ListCompaniesRow): boolean {
  if (
    c.isPersonal ||
    c.isBroker ||
    c.temperature === "☠️ Dead" ||
    c.shipmentFrequency === "SingleShipment"
  ) {
    return false;
  }

  return (
    c.billingLatitude === undefined ||
    c.billingLatitude === 0 ||
    c.billingLongitude === undefined ||
    c.billingLongitude === 0
  );
}

function applyMissingTemperature(c: ListCompaniesRow): boolean {
  return (
    c.qualificationStatus === QualificationStatus.Qualified &&
    (isBlank(c.temperature) || c.temperature === "-")
  );
}

function applyMissingFreightVolume(c: ListCompaniesRow): boolean {
  return (
    c.qualificationStatus === QualificationStatus.Qualified &&
    (isBlank(c.shipmentFrequency) || c.shipmentFrequency === "-")
  );
}

function applyMissingPrimaryContact(c: ListCompaniesRow): boolean {
  return isBlank(c.defaultSalesContactId);
}

function applyAtRisk(c: ListCompaniesRow): boolean {
  return (
    dayjs(c.lastBookedDate).isBefore(dayjs().subtract(2, "months")) &&
    dayjs(c.lastBookedDate).isAfter(dayjs().subtract(3, "months"))
  );
}

function applyLost(c: ListCompaniesRow): boolean {
  return dayjs(c.lastBookedDate).isBefore(dayjs().subtract(3, "months"));
}

function applyMissingDomain(
  _companies: ListCompaniesRow[],
  c: ListCompaniesRow,
): boolean {
  return (
    isEmpty(c.associatedProfessionalDomain) &&
    !c.isPersonal &&
    !c.hasNonProfessionalDomain &&
    c.salesContacts.some((sc) => sc.email)
  );
}

function applySalesContactsWithDuplicateEmails(c: ListCompaniesRow): boolean {
  // We need to ignore the emails if they are known group emails

  const salesContactsWithoutKnownGroupEmails = c.salesContacts.filter(
    function (sc) {
      if (
        sc.email !== undefined &&
        c.knownGroupEmailAddresses?.includes(sc.email)
      ) {
        return false;
      } else {
        return true;
      }
    },
  );

  return hasDuplicates(salesContactsWithoutKnownGroupEmails, (sc) => sc.email);
}

function applySalesContactsWithDuplicateNames(c: ListCompaniesRow): boolean {
  return hasDuplicates(c.salesContacts, (sc) => sc.name);
}

function applySalesContactsWithEmailsNotMatchingDomain(
  companyRow: ListCompaniesRow,
): boolean {
  const apds = companyRow.associatedProfessionalDomain;

  const salesContactEmailDomains = companyRow.salesContacts
    .filter((sc) => sc.email)
    .map((sc) => sc.email!.split("@")[1]);

  let permittedDomains = [...apds];
  if (companyRow.hasNonProfessionalDomain) {
    permittedDomains = [
      ...permittedDomains,
      ...NonCorporateEmailData.map((ed) => removePrefix(ed, "@")),
    ];
  }

  return salesContactEmailDomains.some((ed) => !permittedDomains.includes(ed));
}

interface SalesContactListCompaniesRowPair {
  salesContact: SalesContact;
  company: ListCompaniesRow;
}

function findSalesContactsOnMultipleCompanies(
  companies: ListCompaniesRow[],
  c: ListCompaniesRow,
): SalesContactListCompaniesRowPair[] {
  function findCompaniesWithSalesContactEmails(email: string | undefined) {
    if (isBlank(email)) {
      return [];
    }

    return companies.filter((c) =>
      c.salesContacts.some((sc) => sc.email === email) ? 1 : 0,
    );
  }

  const result = c.salesContacts
    .flatMap(function (sc) {
      if (sc && sc.email) {
        const companies = findCompaniesWithSalesContactEmails(sc.email).filter(
          (company) => company.companyId !== c.companyId,
        );
        return companies.map((company) => ({ salesContact: sc, company }));
      } else {
        return undefined;
      }
    })
    .filter((o) => o);
  return result as SalesContactListCompaniesRowPair[];
}

function applySalesContactEmailOnMultipleCompanies(
  companies: ListCompaniesRow[],
  c: ListCompaniesRow,
): boolean {
  function countCompaniesWithSalesContactEmails(email: string | undefined) {
    if (isBlank(email)) {
      return 0;
    }

    return sum(companies, (c) =>
      c.salesContacts.some((sc) => sc.email === email) ? 1 : 0,
    );
  }

  return (
    countIf(
      c.salesContacts.filter((sc) => sc),
      function (sc) {
        return countCompaniesWithSalesContactEmails(sc.email) > 1;
      },
    ) > 0
  );
}

function applyBrokerAccount(c: ListCompaniesRow): boolean {
  return c.isBroker;
}

function applyBusinessAccount(c: ListCompaniesRow): boolean {
  return !c.isBroker && !c.isPersonal;
}

function applyPersonalAccount(c: ListCompaniesRow): boolean {
  return c.isPersonal;
}

function applyFilter(
  filter: CompanyFilterType,
  companies: ListCompaniesRow[],
  c: ListCompaniesRow,
): boolean {
  if (filter === undefined) {
    return true;
  }

  switch (filter) {
    case CompanyFilterType.Unqualified:
      return applyUnqualifiedFilter(c);
    case CompanyFilterType.NeverQuoted:
      return applyNeverQuotedFilter(c);
    case CompanyFilterType.Quoted:
      return applyQuotedFilter(c);
    case CompanyFilterType.ShippingRookie:
      return applyShippingRookieFilter(c);
    case CompanyFilterType.RegularShipper:
      return applyRegularShipperFilter(c);
    case CompanyFilterType.AtRisk:
      return applyAtRisk(c);
    case CompanyFilterType.Lost:
      return applyLost(c);
    case CompanyFilterType.NeedsVerification:
      return applyNeedsVerificationFilter(c);
    case CompanyFilterType.RecentSignup:
      return applyRecentSignupsFilter(c);
    case CompanyFilterType.SalesCallsSnoozed:
      return applySalesCallsSnoozedFilter(c);
    case CompanyFilterType.OnCredit:
      return applyOnCreditFilter(c);
    case CompanyFilterType.NeverCalled:
      return applyNeverCalledFilter(c);
    case CompanyFilterType.NoSignificantCalls:
      return applyNoSignificantCallsFilter(c);
    case CompanyFilterType.NoSalesContacts:
      return applyNoSalesContactsFilter(c);
    case CompanyFilterType.MissingDomain:
      return applyMissingDomain(companies, c);
    case CompanyFilterType.DuplicateDomains:
      return applyDuplicateDomains(companies, c).length > 0;
    case CompanyFilterType.SalesContactsWithDuplicateEmails:
      return applySalesContactsWithDuplicateEmails(c);
    case CompanyFilterType.SalesContactsWithDuplicateNames:
      return applySalesContactsWithDuplicateNames(c);
    case CompanyFilterType.SalesContactsWithEmailsNotMatchingDomain:
      return applySalesContactsWithEmailsNotMatchingDomain(c);
    case CompanyFilterType.DuplicateCompanyNames:
      return applyDuplicateCompanyNames(companies, c);
    case CompanyFilterType.SalesContactEmailOnMultipleCompanies:
      return applySalesContactEmailOnMultipleCompanies(companies, c);
    case CompanyFilterType.MissingAddress:
      return applyMissingAddress(c);
    case CompanyFilterType.MissingLatitudeLongitude:
      return applyMissingLatitudeLongitude(c);
    case CompanyFilterType.MissingPrimaryContact:
      return applyMissingPrimaryContact(c);
    case CompanyFilterType.MissingTemperature:
      return applyMissingTemperature(c);
    case CompanyFilterType.MissingFreightVolume:
      return applyMissingFreightVolume(c);
    case CompanyFilterType.BrokerAccount:
      return applyBrokerAccount(c);
    case CompanyFilterType.PersonalAccount:
      return applyPersonalAccount(c);
    case CompanyFilterType.BusinessAccount:
      return applyBusinessAccount(c);
    default:
      assertNever(filter);
  }
}

interface TemperatureFilterDropdownProps {
  value: TemperatureFilter | undefined;
  setValue: (_: TemperatureFilter | undefined) => void;
}

function TemperatureFilterDropdown(props: TemperatureFilterDropdownProps) {
  return (
    <Select
      value={props.value}
      onChange={function (e) {
        props.setValue(e);
      }}
      style={{ width: "150px" }}
      placeholder="Hiding Dead"
      allowClear
    >
      <Select.OptGroup label="General">
        <Select.Option value={TemperatureFilter.All}>
          Include Dead
        </Select.Option>
      </Select.OptGroup>

      <Select.OptGroup label="Specific">
        <Select.Option value={TemperatureFilter.Hot}>🔥 Hot</Select.Option>
        <Select.Option value={TemperatureFilter.Warm}>🌤️ Warm</Select.Option>
        <Select.Option value={TemperatureFilter.Cold}>🥶 Cold</Select.Option>
        <Select.Option value={TemperatureFilter.Dead}>☠️ Dead</Select.Option>
      </Select.OptGroup>
    </Select>
  );
}

interface CompanyFilterDropdownProps {
  value: CompanyFilterType | undefined;
  setValue: (_: CompanyFilterType | undefined) => void;
  companies: ListCompaniesRow[];
}

function countForFilterType(
  companies: ListCompaniesRow[],
  filter: CompanyFilterType,
): number {
  return companies.filter(function (c) {
    return applyFilter(filter, companies, c);
  }).length;
}

function countForAllProblemFilterTypes(companies: ListCompaniesRow[]): number {
  let total = 0;

  Object.entries(CompanyFilterType).forEach(function (value) {
    const filter = value[1];

    if (isFilterAProblem(filter)) {
      total += countForFilterType(companies, filter);
    }
  });

  return total;
}

function CompanyFilterDropdown(props: CompanyFilterDropdownProps) {
  const [cache, setCache] = useState<Map<CompanyFilterType, number>>(new Map());

  useEffect(
    function () {
      setCache(new Map());
    },
    [props.companies],
  );

  const countForFilter = function (filter: CompanyFilterType): number {
    if (cache.has(filter)) {
      return cache.get(filter)!;
    } else {
      const value = countForFilterType(props.companies, filter);
      cache.set(filter, value);
      return value;
    }
  };

  return (
    <Select
      value={props.value}
      onChange={function (e) {
        props.setValue(e);
      }}
      style={{ width: "480px" }}
      placeholder="No filter"
      allowClear
      showSearch
    >
      <Select.OptGroup label="Lifecycle">
        <Select.Option value={CompanyFilterType.Unqualified}>
          Lead ({countForFilter(CompanyFilterType.Unqualified)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.NeverQuoted}>
          Never Quoted ({countForFilter(CompanyFilterType.NeverQuoted)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.Quoted}>
          Quoted, never booked ({countForFilter(CompanyFilterType.Quoted)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.ShippingRookie}>
          Booked 1-4 Shipments (
          {countForFilter(CompanyFilterType.ShippingRookie)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.RegularShipper}>
          Booked 5+ Shipments (
          {countForFilter(CompanyFilterType.RegularShipper)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.AtRisk}>
          At Risk ({countForFilter(CompanyFilterType.AtRisk)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.Lost}>
          Lost ({countForFilter(CompanyFilterType.Lost)})
        </Select.Option>
      </Select.OptGroup>

      <Select.OptGroup label="Account Type">
        <Select.Option value={CompanyFilterType.BusinessAccount}>
          Business Accounts ({countForFilter(CompanyFilterType.BusinessAccount)}
          )
        </Select.Option>
        <Select.Option value={CompanyFilterType.BrokerAccount}>
          Broker Accounts ({countForFilter(CompanyFilterType.BrokerAccount)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.PersonalAccount}>
          Personal Accounts ({countForFilter(CompanyFilterType.PersonalAccount)}
          )
        </Select.Option>
      </Select.OptGroup>

      <Select.OptGroup label="Other">
        <Select.Option value={CompanyFilterType.NeedsVerification}>
          Needs Verification (
          {countForFilter(CompanyFilterType.NeedsVerification)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.RecentSignup}>
          Recent Signup (Last 30 days) (
          {countForFilter(CompanyFilterType.RecentSignup)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.SalesCallsSnoozed}>
          Sales Calls Snoozed (
          {countForFilter(CompanyFilterType.SalesCallsSnoozed)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.OnCredit}>
          On Credit ({countForFilter(CompanyFilterType.OnCredit)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.NeverCalled}>
          Never Called ({countForFilter(CompanyFilterType.NeverCalled)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.NoSignificantCalls}>
          No Significant Calls (
          {countForFilter(CompanyFilterType.NoSignificantCalls)})
        </Select.Option>
      </Select.OptGroup>

      <Select.OptGroup label="Problems">
        <Select.Option value={CompanyFilterType.NoSalesContacts}>
          No Sales Contacts ({countForFilter(CompanyFilterType.NoSalesContacts)}
          )
        </Select.Option>
        <Select.Option value={CompanyFilterType.MissingDomain}>
          Missing Domain ({countForFilter(CompanyFilterType.MissingDomain)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.DuplicateDomains}>
          Duplicate Domains (
          {countForFilter(CompanyFilterType.DuplicateDomains)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.DuplicateCompanyNames}>
          Duplicate Company Names (
          {countForFilter(CompanyFilterType.DuplicateCompanyNames)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.MissingAddress}>
          Incomplete Address ({countForFilter(CompanyFilterType.MissingAddress)}
          )
        </Select.Option>
        <Select.Option value={CompanyFilterType.MissingLatitudeLongitude}>
          Missing Latitude/Longitude (
          {countForFilter(CompanyFilterType.MissingLatitudeLongitude)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.MissingPrimaryContact}>
          Missing Primary Contact (
          {countForFilter(CompanyFilterType.MissingPrimaryContact)})
        </Select.Option>
        <Select.Option
          value={CompanyFilterType.SalesContactsWithDuplicateEmails}
        >
          Sales Contacts with duplicate emails (
          {countForFilter(CompanyFilterType.SalesContactsWithDuplicateEmails)})
        </Select.Option>
        <Select.Option
          value={CompanyFilterType.SalesContactsWithDuplicateNames}
        >
          Sales Contacts with duplicate names (
          {countForFilter(CompanyFilterType.SalesContactsWithDuplicateNames)})
        </Select.Option>
        <Select.Option
          value={CompanyFilterType.SalesContactEmailOnMultipleCompanies}
        >
          Sales Contacts Email on Multiple Companies (
          {countForFilter(
            CompanyFilterType.SalesContactEmailOnMultipleCompanies,
          )}
          )
        </Select.Option>
        <Select.Option
          value={CompanyFilterType.SalesContactsWithEmailsNotMatchingDomain}
        >
          Sales Contacts with emails not matching domain (
          {countForFilter(
            CompanyFilterType.SalesContactsWithEmailsNotMatchingDomain,
          )}
          )
        </Select.Option>
        <Select.Option value={CompanyFilterType.MissingFreightVolume}>
          Missing Freight Volume (
          {countForFilter(CompanyFilterType.MissingFreightVolume)})
        </Select.Option>

        <Select.Option value={CompanyFilterType.MissingTemperature}>
          Missing Temperature (
          {countForFilter(CompanyFilterType.MissingTemperature)})
        </Select.Option>
      </Select.OptGroup>
    </Select>
  );
}

interface CompaniesTableProps {
  allCompanies: ListCompaniesRow[];
  companies: Array<ListCompaniesRow>;
}

function companyNameDescription(o: ListCompaniesRow) {
  const uq =
    o.qualificationStatus === QualificationStatus.Unqualified ? "🎈" : "";
  const sv = emojiForShipmentVolume(o.shipmentFrequency);
  const temp = emojiForTemperature(o.temperature);
  return [uq, temp, o.companyName, sv].filter((o) => o).join(" ");
}

interface CompaniesTableRowProps {
  allCompanies: ListCompaniesRow[];
  companies: Array<ListCompaniesRow>;
  currentCompany: ListCompaniesRow;
}

interface OverlayForSalesContactEmailOnMultipleCompaniesProps {
  allCompanies: ListCompaniesRow[];
  currentCompany: ListCompaniesRow;
}

function OverlayForSalesContactEmailOnMultipleCompanies(
  props: OverlayForSalesContactEmailOnMultipleCompaniesProps,
) {
  const matching = findSalesContactsOnMultipleCompanies(
    props.allCompanies,
    props.currentCompany,
  );

  const columns: DataTableColumn<SalesContactListCompaniesRowPair>[] = [
    {
      title: "Name",
      render: (o) => <div>{o.salesContact.name}</div>,
    },

    {
      title: "Email",
      render: (o) => <div>{o.salesContact.email}</div>,
    },

    {
      title: "Company",
      render: (o) => <div>{o.company.companyName}</div>,
    },

    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <ViewCompanyButton companyId={o.company.companyId!} />
        </ButtonRow>
      ),
    },
  ];

  return (
    <div style={{ marginTop: "16px" }}>
      <DataTable pagination={false} columns={columns} data={matching} />
    </div>
  );
}

function CompaniesTableRow(props: CompaniesTableRowProps) {
  const [duplicatedDomains] = useState<string[]>(
    applyDuplicateDomains(props.allCompanies, props.currentCompany),
  );

  return (
    <Stack align="left">
      <div>
        <div style={{ width: "200px" }}>
          {companyNameDescription(props.currentCompany)}
        </div>
      </div>
      <Spacer height={4} />
      <Stack align="left" style={{ marginLeft: "-8px", gap: "4px" }}>
        <CompanyStarredTag companyId={props.currentCompany.companyId} />
        <CompanySalesCallsSnoozedTag
          snoozeSalesCallsUntil={props.currentCompany.snoozeSalesCallsUntil}
        />
        {duplicatedDomains.length > 0 && (
          <Tag color="orange">
            <WarningComponent /> Duplicate Domains:
            {duplicatedDomains.map((d, i) =>
              i === 0 ? (
                <span key={d}> {d} </span>
              ) : (
                <span key={d}>- {d} </span>
              ),
            )}
          </Tag>
        )}
        {applyDuplicateCompanyNames(
          props.allCompanies,
          props.currentCompany,
        ) && (
          <Tag color="orange">
            <WarningComponent /> Duplicate Company Name
          </Tag>
        )}
        {applyMissingDomain(props.allCompanies, props.currentCompany) && (
          <Tag color="orange">
            <WarningComponent /> Missing Email Domain
          </Tag>
        )}
        {applyNoSalesContactsFilter(props.currentCompany) && (
          <Tag color="orange">
            <WarningComponent /> No Sales Contacts
          </Tag>
        )}
        {applySalesContactsWithDuplicateEmails(props.currentCompany) && (
          <Tag color="orange">
            <WarningComponent /> Sales contacts with dupe emails
          </Tag>
        )}
        {applySalesContactsWithDuplicateNames(props.currentCompany) && (
          <Tag color="orange">
            <WarningComponent /> Sales contacts with dupe names
          </Tag>
        )}
        {applySalesContactsWithEmailsNotMatchingDomain(
          props.currentCompany,
        ) && (
          <Tag color="orange">
            <WarningComponent /> Sales contacts do not match domain
          </Tag>
        )}
        {applySalesContactEmailOnMultipleCompanies(
          props.allCompanies,
          props.currentCompany,
        ) && (
          <Tooltip
            placement="right"
            overlayInnerStyle={{
              width: "800px",
            }}
            overlay={
              <OverlayForSalesContactEmailOnMultipleCompanies
                allCompanies={props.allCompanies}
                currentCompany={props.currentCompany}
              />
            }
          >
            <Tag color="orange">
              <WarningComponent /> Sales contact on multiple company...
            </Tag>
          </Tooltip>
        )}
        {applyMissingFreightVolume(props.currentCompany) && (
          <Tag color="orange">
            <WarningComponent /> Missing Freight Volume
          </Tag>
        )}
        {applyMissingTemperature(props.currentCompany) && (
          <Tag color="orange">
            <WarningComponent /> Missing Temperature
          </Tag>
        )}
        {applyMissingPrimaryContact(props.currentCompany) && (
          <Tag color="orange">
            <WarningComponent /> Missing Primary Contact
          </Tag>
        )}
        {applyLost(props.currentCompany) && <Tag color="red">❌ Lost</Tag>}
        {applyAtRisk(props.currentCompany) && (
          <Tag color="orange">🚨 At Risk</Tag>
        )}
        ,
      </Stack>
    </Stack>
  );
}

export function CompaniesTable(props: CompaniesTableProps) {
  const columns: DataTableColumn<ListCompaniesRow>[] = [
    {
      key: "companyName",
      show: true,
      title: "Company Name",
      render: (o) => (
        <CompaniesTableRow
          allCompanies={props.allCompanies}
          companies={props.companies}
          currentCompany={o}
        />
      ),
      sorter: function (a, b) {
        return b.companyName.localeCompare(a.companyName);
      },
    },
    {
      title: "Account Type",
      show: false,
      render: (o) => {
        if (o.isBroker) {
          return <Tag color="purple">Broker Account</Tag>;
        }
        if (o.isPersonal) {
          return <Tag color="magenta">Personal Account</Tag>;
        }
        return <Tag color="default">Business Account</Tag>;
      },
    },
    {
      title: "Lead Source",
      key: "leadSource",
      show: true,
      render: (o) => <span>{o.leadSource}</span>,
      sorter: function (a, b) {
        return b.leadSource.localeCompare(a.leadSource);
      },
    },
    {
      title: "# Quotes",
      key: "quotes",
      show: true,
      render: (o) => (
        <Stack align="left">
          {o.quotesRan > 0 && <CellLabel>Quotes:</CellLabel>}
          {o.quotesRan > 0 && <div>{o.quotesRan}</div>}
          {o.quotesRan === 0 && <div>-</div>}
        </Stack>
      ),
      sorter: function (a, b) {
        return b.quotesRan - a.quotesRan;
      },
    },
    {
      title: "# Shipments",
      key: "shipments",
      show: true,
      render: (o) => (
        <Stack align="left">
          {o.shipmentsBooked > 0 && <CellLabel>Booked:</CellLabel>}
          {o.shipmentsBooked > 0 && <div>{o.shipmentsBooked}</div>}
          {o.shipmentsBooked === 0 && <div>-</div>}
        </Stack>
      ),
      sorter: function (a, b) {
        return b.shipmentsBooked - a.shipmentsBooked;
      },
    },
    {
      title: "Created",
      key: "created",
      show: false,
      render: (o) => (
        <Stack align="left">
          {o.createdAt && <CellLabel>Created:</CellLabel>}
          <CreatedAt days skipDelta timestamp={o.createdAt} />
        </Stack>
      ),
      sorter: function (a, b) {
        return dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf();
      },
    },
    {
      title: "First Quoted",
      key: "firstQuoted",
      show: true,
      render: (o) => (
        <Stack align="left">
          {o.firstQuoteDate && <CellLabel>First Quote:</CellLabel>}
          <CreatedAt days skipDelta timestamp={o.firstQuoteDate} />
        </Stack>
      ),
      sorter: function (a, b) {
        return (
          dayjs(a.firstQuoteDate).valueOf() - dayjs(b.firstQuoteDate).valueOf()
        );
      },
    },
    {
      title: "Last Quoted",
      key: "lastQuoted",
      show: true,
      render: (o) => (
        <Stack align="left">
          {o.lastQuotedDate && <CellLabel>Last Quote:</CellLabel>}
          <CreatedAt days skipDelta timestamp={o.lastQuotedDate} />
        </Stack>
      ),
      sorter: function (a, b) {
        return (
          dayjs(a.lastQuotedDate).valueOf() - dayjs(b.lastQuotedDate).valueOf()
        );
      },
    },
    {
      title: "First Booked",
      key: "firstBooked",
      show: false,
      render: (o) => (
        <Stack align="left">
          {o.firstBookedDate && <CellLabel>First Booked:</CellLabel>}
          <CreatedAt days skipDelta timestamp={o.firstBookedDate} />
        </Stack>
      ),
      sorter: function (a, b) {
        return (
          dayjs(a.firstBookedDate).valueOf() -
          dayjs(b.firstBookedDate).valueOf()
        );
      },
    },
    {
      title: "Last Booked",
      key: "lastBooked",
      show: true,
      render: (o) => (
        <Stack align="left">
          {o.lastBookedDate && <CellLabel>Last Booked:</CellLabel>}
          <CreatedAt days skipDelta timestamp={o.lastBookedDate} />
        </Stack>
      ),
      sorter: function (a, b) {
        return (
          dayjs(a.lastBookedDate).valueOf() - dayjs(b.lastBookedDate).valueOf()
        );
      },
    },
    {
      title: "Last Sales Call",
      key: "lastSalesCall",
      show: true,
      render: (o) => (
        <Stack align="left">
          {o.lastCallDate && <CellLabel>Last Sales Call:</CellLabel>}
          <CreatedAt days skipDelta timestamp={o.lastCallDate} />
          {o.lastCallBy && <CellSubText>{o.lastCallBy}</CellSubText>}
        </Stack>
      ),
      sorter: function (a, b) {
        return (
          dayjs(a.lastCallDate).valueOf() - dayjs(b.lastCallDate).valueOf()
        );
      },
    },
    {
      title: "Last Significant Sales Call",
      key: "lastSignificantSalesCall",
      show: true,
      render: (o) => (
        <Stack align="left">
          {o.lastSignificantCallDate && (
            <CellLabel>Last Significant Call:</CellLabel>
          )}
          <HorizontalStack>
            {isNotBlank(o.lastSignificantCallDate) && (
              <CreatedAt days skipDelta timestamp={o.lastSignificantCallDate} />
            )}
            {isBlank(o.lastSignificantCallDate) && (
              <div>No Significant Calls </div>
            )}
            {o.overdueSalesCall && (
              <div style={{ marginLeft: "4px" }}>
                <Tooltip
                  title={
                    <Stack align="left">
                      <div style={{ fontWeight: "600" }}>
                        Overdue Significant Call
                      </div>
                      <div>
                        Rules: Must be 2 weeks since last call. Must be not
                        snoozed. Must be beyond the threshold weeks since
                        significant call (4 weeks for occasionals, 2-4 for
                        weekly and daily depending on where they are in the
                        sales cycle
                      </div>
                    </Stack>
                  }
                >
                  🚨
                </Tooltip>
              </div>
            )}
          </HorizontalStack>
          {o.lastSignificantCallBy && (
            <CellSubText>{o.lastSignificantCallBy}</CellSubText>
          )}
        </Stack>
      ),
      sorter: function (a, b) {
        return (
          dayjs(a.lastSignificantCallDate).valueOf() -
          dayjs(b.lastSignificantCallDate).valueOf()
        );
      },
    },
    {
      title: "Location",
      key: "location",
      show: true,
      render: (o) => (
        <div>
          {o.billingCity}, {o.billingState}
        </div>
      ),
      sorter: function (a, b) {
        return (
          dayjs(a.lastBookedDate).valueOf() - dayjs(b.lastBookedDate).valueOf()
        );
      },
    },
    {
      title: "Currency",
      key: "currency",
      show: false,
      render: (o) => <div>{o.currency}</div>,
      sorter: function (a, b) {
        return b.currency.localeCompare(a.currency);
      },
    },
    {
      title: "Actions",
      key: "actions",
      show: true,
      render: function (o) {
        return (
          <HorizontalStack>
            <ViewCompanyButton companyId={o.companyId} />
          </HorizontalStack>
        );
      },
    },
    {
      title: "Advanced Actions",
      key: "advancedActions",
      show: false,
      render: function (o) {
        return (
          <HorizontalStack>
            <ViewJsonButton data={o} title="View Raw" />
          </HorizontalStack>
        );
      },
    },
  ];

  return <DataTable pagination={{}} columns={columns} data={props.companies} />;
}

export function ListCompaniesScreen() {
  const createCompaniesApi = useCompaniesApi();
  const [allCompanies, setAllCompanies] = useState<
    Array<ListCompaniesRow> | undefined
  >(undefined);
  const [searchValue, setSearchValue] = useParamState({
    key: "searchValue",
    defaultValue: "",
  });

  // Combination of a debounced searchValue that is used for the filtering
  // and an instantly updated searchValueDisplay that is use to show what was typed
  // TODO: Would be better if we had a DebouncedInput component
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchValueDisplay, setSearchValueDisplay] = useState(searchValue);
  const setSearchValueDebounced = useDebouncedCallback(
    // function
    (value) => {
      setSearchLoading(false);
      setSearchValue(value);
    },
    // delay in ms
    1000,
  );

  const [
    locationFilterIdentifiers,
    setLocationFilterIdentifiers,
    serializedLocationFilterIdentifiers,
  ] = useParamState<string[] | undefined>({
    key: "locationFilter",
    defaultValue: undefined,
  });

  const [firstQuoteFilter, setFirstQuoteFilter] = useParamState<
    string | undefined
  >({
    key: "firstQuoteFilter",
    defaultValue: undefined,
  });

  const [temperatureFilter, setTemperatureFilter] = useParamState<
    TemperatureFilter | undefined
  >({
    key: "temperatureFilter",
    defaultValue: undefined,
  });

  const [onlyIncludeOverdueSalesCall, setOnlyIncludeOverdueSalesCall] =
    useParamState({
      key: "onlyIncludeOverdueSalesCall",
      defaultValue: false,
    });

  const [hideUninterestingCompanies, setHideUninterestingCompanies] =
    useParamState({
      key: "hideUninterestingCompanies",
      defaultValue: true,
    });

  const [filter, setFilter] = useParamState<CompanyFilterType | undefined>({
    key: "generalFilter",
    defaultValue: undefined,
  });

  const [frequencyFilter, setFrequencyFilter] = useParamState<string[]>({
    key: "frequencyFilter",
    defaultValue: [],
  });

  const [loading, setLoading] = useState(false);

  const [
    countForAllProblemFilterTypesCached,
    setCountForAllProblemFilterTypesCached,
  ] = useState<number>();

  async function refresh() {
    setLoading(true);
    const companiesApi = await createCompaniesApi();
    const response = await companiesApi.listCompanies({
      listCompaniesInput: {
        locationFilterIdentifiers: locationFilterIdentifiers,
      },
    });
    setAllCompanies(response);
    setLoading(false);
  }

  useEffect(
    function () {
      refresh();
    },

    [serializedLocationFilterIdentifiers, onlyIncludeOverdueSalesCall],
  );

  function doGeneralFilter(c: ListCompaniesRow): boolean {
    if (allCompanies === undefined) {
      return true;
    }
    if (filter === undefined) {
      return true;
    }
    return applyFilter(filter, allCompanies, c);
  }

  const filteredByEverythingApartFromGeneralFilterAndFirstQuote = allCompanies
    ?.filter(function (c) {
      if (searchValue === "") {
        return true;
      }
      return (
        c.companyName.toLowerCase().includes(searchValue.toLowerCase()) ||
        (c.associatedProfessionalDomain &&
          c.associatedProfessionalDomain
            .map((d) => d.toLowerCase())
            .join()
            .includes(searchValue.toLowerCase()))
      );
    })
    .filter(function (c) {
      return (
        isEmpty(frequencyFilter) ||
        frequencyFilter.includes(c.shipmentFrequency)
      );
    })
    .filter(function (c) {
      if (hideUninterestingCompanies) {
        return (
          (c.shipmentFrequency !== "SingleShipment" &&
            c.temperature !== "☠️ Dead" &&
            !c.isPersonal &&
            !c.isBroker) ||
          c.shipmentsBooked > 0
        );
      } else {
        return true;
      }
    })
    .filter(function (c) {
      switch (temperatureFilter) {
        case TemperatureFilter.All:
          return true;
        case TemperatureFilter.Hot:
          return c.temperature === "🔥 Hot";
        case TemperatureFilter.Warm:
          return c.temperature === "🌤 Warm";
        case TemperatureFilter.Cold:
          return c.temperature === "🥶 Cold";
        case TemperatureFilter.Dead:
          return c.temperature === "☠️ Dead";
        case undefined:
          return c.temperature !== "☠️ Dead";
        default:
          assertNever(temperatureFilter);
      }
    })

    .filter(function (c) {
      if (onlyIncludeOverdueSalesCall) {
        return c.overdueSalesCall;
      } else {
        return true;
      }
    });

  const filteredByEverythingApartFromGeneralFilter =
    filteredByEverythingApartFromGeneralFilterAndFirstQuote?.filter(
      function (c) {
        if (firstQuoteFilter !== undefined) {
          return (
            c.firstQuoteDate !== undefined &&
            dayjs(c.firstQuoteDate).format(PeriodFormats.Monthly) ===
              firstQuoteFilter
          );
        } else {
          return true;
        }
      },
    );

  const filteredCompanies =
    filteredByEverythingApartFromGeneralFilter?.filter(doGeneralFilter);

  useEffect(
    function () {
      if (filteredCompanies !== undefined) {
        const count = countForAllProblemFilterTypes(filteredCompanies);
        setCountForAllProblemFilterTypesCached(count);
      }
    },
    [filteredCompanies],
  );

  if (
    allCompanies === undefined ||
    filteredCompanies === undefined ||
    filteredByEverythingApartFromGeneralFilter === undefined
  ) {
    return <Loading />;
  } else {
    return (
      <>
        <Page
          title={
            <div>
              List Companies ({filteredCompanies.length}/{allCompanies.length})
            </div>
          }
          tags={[]}
          stats={
            <Stack align="left">
              <HorizontalStack verticalAlign="top">
                <Form.Item
                  label={
                    <>
                      Name{" "}
                      {searchLoading ? (
                        <Spin
                          size="small"
                          style={{
                            width: "8px",
                            marginLeft: "6px",
                            marginRight: "6px",
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  }
                >
                  <Input
                    value={searchValueDisplay}
                    placeholder="Filter by company name"
                    allowClear
                    onChange={function (e) {
                      setSearchLoading(true);
                      setSearchValueDebounced(e.target.value);
                      setSearchValueDisplay(e.target.value);
                    }}
                  />
                </Form.Item>
                <Spacer width={16} />

                <Form.Item label="Frequency">
                  <ValuesDropdown
                    mode="multiple"
                    value={frequencyFilter}
                    setValue={setFrequencyFilter}
                    values={allCompanies.map((c) => c.shipmentFrequency)}
                    width={180}
                    placeholder="No filter"
                  />
                </Form.Item>
                <Spacer width={16} />
                <Form.Item label="Temperature">
                  <TemperatureFilterDropdown
                    value={temperatureFilter}
                    setValue={setTemperatureFilter}
                  />
                </Form.Item>
                <Spacer width={16} />
                <Form.Item label="First Quote">
                  {filteredByEverythingApartFromGeneralFilterAndFirstQuote && (
                    <MonthSelect
                      month={firstQuoteFilter}
                      setMonth={setFirstQuoteFilter}
                      counter={function (month: string) {
                        return countIf(
                          filteredByEverythingApartFromGeneralFilterAndFirstQuote,
                          (c) =>
                            c.firstQuoteDate !== undefined &&
                            dayjs(c.firstQuoteDate).format(
                              PeriodFormats.Monthly,
                            ) === month,
                        );
                      }}
                    />
                  )}
                </Form.Item>
                <Spacer width={16} />
                <Form.Item label="Location">
                  <LocationDropdown
                    locationFilterIdentifiers={locationFilterIdentifiers}
                    setLocationFilterIdentifiers={setLocationFilterIdentifiers}
                  />
                </Form.Item>
                <Spacer width={16} />
                <Form.Item label="Filter">
                  <CompanyFilterDropdown
                    value={filter}
                    setValue={setFilter}
                    companies={filteredByEverythingApartFromGeneralFilter}
                  />
                </Form.Item>
                <Spacer width={16} />
              </HorizontalStack>
              <HorizontalStack>
                <Form.Item label="Only Overdue Sales Call?">
                  <Switch
                    checked={onlyIncludeOverdueSalesCall}
                    onChange={setOnlyIncludeOverdueSalesCall}
                  />
                </Form.Item>
                <Spacer width={16} />
                <Form.Item label="Hide Uninteresting Companies">
                  <Tooltip
                    title={
                      <>
                        Hides ☠️ dead, single shipment, personal, or broker
                        companies unless they have shipments booked.
                      </>
                    }
                  >
                    <Switch
                      checked={hideUninterestingCompanies}
                      onChange={setHideUninterestingCompanies}
                    />
                  </Tooltip>
                </Form.Item>
              </HorizontalStack>
              <HorizontalStack>
                <div style={{ margin: "4px" }}>
                  <>
                    🎈 Lead:{" "}
                    {
                      filteredCompanies.filter(
                        (o) =>
                          o.qualificationStatus ===
                          QualificationStatus.Unqualified,
                      ).length
                    }{" "}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    🔥 Hot:{" "}
                    {
                      filteredCompanies.filter(
                        (o) => o.temperature === "🔥 Hot",
                      ).length
                    }{" "}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    🌤 Warm:{" "}
                    {
                      filteredCompanies.filter(
                        (o) => o.temperature === "🌤 Warm",
                      ).length
                    }{" "}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    🥶 Cold:{" "}
                    {
                      filteredCompanies.filter(
                        (o) => o.temperature === "🥶 Cold",
                      ).length
                    }{" "}
                  </>
                </div>
                <Spacer width={32} />
                <div style={{ margin: "4px" }}>
                  <>
                    🐳 Daily:{" "}
                    {
                      filteredCompanies.filter(
                        (o) => o.shipmentFrequency === "Daily",
                      ).length
                    }{" "}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    🐬 Weekly:{" "}
                    {
                      filteredCompanies.filter(
                        (o) => o.shipmentFrequency === "Weekly",
                      ).length
                    }{" "}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    🤙 Occasional:{" "}
                    {
                      filteredCompanies.filter(
                        (o) => o.shipmentFrequency === "Occasional",
                      ).length
                    }{" "}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>🚨 Problems: {countForAllProblemFilterTypesCached}</>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    ☎️ Significant Call:{" "}
                    {(
                      (100 *
                        countIf(filteredCompanies, (c) =>
                          isNotBlank(c.lastSignificantCallDate),
                        )) /
                      filteredCompanies.length
                    ).toFixed(0)}
                    {"%"}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    🚨 Overdue Call:{" "}
                    {countIf(filteredCompanies, (c) => c.overdueSalesCall)} /{" "}
                    {filteredCompanies.length}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    ✅ Booked:{" "}
                    {countIf(filteredCompanies, (c) => c.shipmentsBooked > 0)} /{" "}
                    {filteredCompanies.length}
                  </>
                </div>
              </HorizontalStack>
            </Stack>
          }
          extra={[
            <ButtonRow key="add">
              <AddCompanyButton />
            </ButtonRow>,
          ]}
        >
          <PageTitle>List Companies</PageTitle>
          <Panel>
            {!loading && (
              <CompaniesTable
                allCompanies={allCompanies}
                companies={filteredCompanies}
              />
            )}
            {loading && <Loading />}
          </Panel>
        </Page>
      </>
    );
  }
}
