import { ClaudeConversationItemResult } from "@freightsimple/generated-apollo-openapi-client";
import { SendOutlined } from "@ant-design/icons";
import { Button, Input, List, Modal, Spin, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useClaudeApi } from "../../Apis/Apis";
import HorizontalStack from "../../Components/HorizontalStack";
import {
  CombinedProps,
  getClaudeConversationIds,
} from "./ClaudeConversationType";

export function ClaudeButton(props: CombinedProps) {
  const [messages, setMessages] = useState<ClaudeConversationItemResult[]>([]);
  const [inputText, setInputText] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const title = `🤖 Claude`;
  const createClaudeApi = useClaudeApi();
  let chatTitle = "";

  const ids = getClaudeConversationIds(props.context, props);

  const shipmentId = ids.shipmentId;
  const companyId = ids.companyId;
  chatTitle = ids.chatTitle;

  async function getMessages() {
    setLoading(true);
    try {
      const claudeApi = await createClaudeApi();
      const response = await claudeApi.getClaudeConversationItems({
        shipmentId,
        companyId,
        context: props.context,
      });
      setMessages(response);
    } catch {
      message.error("Error loading");
    }

    setLoading(false);
  }

  useEffect(
    function () {
      if (isModalVisible) {
        getMessages();
      }
    },
    [isModalVisible],
  );

  const handleSend = async () => {
    if (inputText.trim() === "") return;

    setIsProcessing(true);

    const claudeApi = await createClaudeApi();
    try {
      const sending = claudeApi.sendClaudeMessage({
        shipmentId,
        companyId,
        message: inputText,
        context: props.context,
      });
      setMessages([
        ...messages,
        {
          role: "user",
          html: inputText,
          apolloEmail: "",
          createdAt: "",
        },
      ]);
      setInputText("");
      await sending;
      await getMessages();
    } catch (e) {
      message.error(
        `Oops. Something went wrong ${e}. Maybe try reloading and seeing if it worked?`,
      );
    }
    setIsProcessing(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={
          <HorizontalStack align="spread">
            <div>Claude {chatTitle} Conversations</div>
          </HorizontalStack>
        }
        open={isModalVisible}
        destroyOnClose={true}
        width={1400}
        onCancel={handleCancel}
        footer={null}
      >
        <div
          style={{ height: "600px", overflowY: "auto", marginBottom: "20px" }}
        >
          <List
            itemLayout="horizontal"
            dataSource={messages}
            renderItem={(message) => {
              let formattedDate = "";
              if (message.createdAt) {
                formattedDate = Intl.DateTimeFormat("en-US", {
                  weekday: "short",
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }).format(new Date(message.createdAt));
              }
              return (
                <List.Item
                  style={{
                    flexDirection: "column",
                    alignItems:
                      message.role === "user" ? "flex-end" : "flex-start",
                  }}
                >
                  <div
                    style={{
                      fontSize: "0.5rem",
                      color: "#666",
                      marginBottom: "4px",
                    }}
                  >
                    {message.role === "user" ? (
                      <>
                        <span>{message.apolloEmail} </span>
                        <span> {formattedDate}</span>
                      </>
                    ) : (
                      <span>{formattedDate}</span>
                    )}
                  </div>
                  <div
                    style={{
                      background:
                        message.role === "user" ? "#1890ff" : "#f0f0f0",
                      color: message.role === "user" ? "white" : "black",
                      padding: "8px 12px",
                      borderRadius: "12px",
                      maxWidth: "70%",
                      fontSize: "12px",
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: message.html }} />
                  </div>
                </List.Item>
              );
            }}
          />
          <div ref={messagesEndRef} />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Input
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onPressEnter={handleSend}
            placeholder="Type your message here..."
            disabled={isProcessing}
          />
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={handleSend}
            style={{ marginLeft: "10px" }}
            disabled={isProcessing}
          >
            Send
          </Button>
        </div>
        {isProcessing && (
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <Spin /> Processing...
          </div>
        )}
        {loading && messages.length === 0 && (
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <Spin /> Loading messages...
          </div>
        )}
      </Modal>
      <Button onClick={showModal}>{title}</Button>
    </>
  );
}
