import {
  CustomerInvoice,
  CustomerInvoiceState,
  CustomerInvoiceType,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, Form, message, Modal } from "antd";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import { useDisplay } from "../../Hooks/useDisplay";
import { useState } from "react";
import HorizontalStack from "../../Components/HorizontalStack";
import TextArea from "antd/es/input/TextArea";
import { isBlank } from "@freightsimple/shared";

interface FullCustomerRefundButtonProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
}

export function FullCustomerRefundButton(props: FullCustomerRefundButtonProps) {
  const createCustomerInvoiceApi = useCustomerInvoiceApi();
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!;
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState("");
  const display = useDisplay();
  async function confirm() {
    if (isBlank(reason)) {
      message.warning("You need to describe a reason!");
      return;
    }
    setIsLoading(true);
    const customerInvoiceApi = await createCustomerInvoiceApi();
    try {
      await customerInvoiceApi.voidOrRefundFullInvoice({
        customerInvoiceId,
        reason,
      });
      message.success("Refund processed");
      await props.onRefresh();
    } catch (e: any) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
    setIsLoading(false);
  }

  const enabled =
    props.customerInvoice.customerInvoiceState ===
      CustomerInvoiceState.Settled ||
    props.customerInvoice.customerInvoiceState ===
      CustomerInvoiceState.Reconciled;

  const isRefundable =
    props.customerInvoice.invoiceType === CustomerInvoiceType.ShipmentCharge ||
    props.customerInvoice.invoiceType === CustomerInvoiceType.AdditionalCharge;

  if (!enabled || !isRefundable) {
    return <></>;
  }

  return (
    <>
      <Modal
        title="Are you sure to refund this invoice?"
        open={display.status}
        onOk={confirm}
        okText="Yes, refund this full invoice"
        onCancel={display.hide}
        width={400}
        confirmLoading={isLoading}
        destroyOnClose
      >
        <HorizontalStack>
          <Form.Item label="Customer Visible Reason">
            <TextArea
              cols={55}
              value={reason}
              onChange={(event) => setReason(event.target.value)}
            />
          </Form.Item>
        </HorizontalStack>
      </Modal>
      <Button disabled={!enabled} onClick={display.show} danger>
        🔙 Refund Full Invoice
      </Button>
    </>
  );
}
