import { ApolloMapCompany } from "@freightsimple/generated-apollo-openapi-client";
import { Select } from "antd";
import { useState } from "react";
import { useMapsApi } from "../../Apis/Apis";
import { useOnce } from "../../Hooks/useOnce";
import { SelectProps } from "antd/lib";

interface CompaniesDropdownProps {
  companyId: string | undefined | null;
  setCompanyId: (_: string | undefined) => void;
  disabled?: boolean;
}

export function CompaniesDropdown(props: CompaniesDropdownProps) {
  const createMapsApi = useMapsApi();
  const [companies, setCompanies] = useState<ApolloMapCompany[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const options: SelectProps["options"] = companies.map((c) => ({
    value: c.companyId,
    label: c.companyName,
  }));

  useOnce(async function () {
    setIsLoading(true);
    try {
      const mapsApi = await createMapsApi();
      const response = await mapsApi.getCompanies();
      setCompanies(response);
    } catch (e) {
      console.error("Error fetching the companies - ", e);
    }
    setIsLoading(false);
  });

  return (
    <Select
      loading={isLoading}
      allowClear={true}
      showSearch
      options={options}
      style={{ width: 300 }}
      value={props.companyId}
      placeholder="Company"
      optionFilterProp="children"
      onSelect={function (_companyId: string) {
        props.setCompanyId(_companyId);
      }}
      onClear={function () {
        props.setCompanyId(undefined);
      }}
      filterOption={function (input, option) {
        if (option === undefined) {
          return false;
        }
        return (
          option.label
            ?.toString()
            .toLowerCase()
            .includes(input.toLowerCase()) ?? false
        );
      }}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toString()
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toString().toLowerCase())
      }
    />
  );
}
