import { Table, TablePaginationConfig } from "antd";

import { CSSProperties, ReactNode, useState } from "react";
import { CSVLink } from "react-csv";
import { safeColumns } from "../Helpers/safeColumns";
import Spacer from "../Spacer";
import Colors from "./Colors";
import HorizontalStack from "./HorizontalStack";
import Stack from "./Stack";
import { GetRowKey, TableRowSelection } from "../Types/AntHelperTypes";
import { ShowHideColumnsPopover } from "./ShowHideColumnsPopover";

export interface DataTableColumn<T> {
  ellipsis?: boolean | { showTitle?: boolean };
  key?: string;
  show?: boolean;
  title: ReactNode;
  subtitle?: ReactNode;
  render: (item: T, index: number) => ReactNode;
  sorter?: (a: T, b: T) => number;
  width?: number | string;
  fixed?: "left" | "right";
}

export interface DataTableCsvColumn<T> {
  title: string;
  render: (item: T, index: number) => ReactNode;
}

export interface DataTableProps<T> {
  data: Array<T>;
  columns: Array<DataTableColumn<T>>;
  pagination?: false | TablePaginationConfig;
  csvColumns?: DataTableCsvColumn<T>[];
  rowKey?: string | GetRowKey<T>;
  rowSelection?: TableRowSelection<T>;
  scroll?: { x: string };
  style?: CSSProperties;
  showHeader?: boolean;
  showColumnPicker?: boolean;
}

type CsvRow = { [key: string]: unknown };

// If you want to show the column pickup, then mark at least one column with field 'show=false' and it will automatically appear
export function DataTable<T extends object>(props: DataTableProps<T>) {
  const fullColumns = props.columns;
  const showHeader = props.showHeader ?? true;
  // Just store boolean array of visibility states
  const [columnVisibility, setColumnVisibility] = useState<boolean[]>(() =>
    fullColumns.map((c) => (c.show === undefined ? true : c.show)),
  );

  // Simple array filter using the visibility array

  const columns = props.columns.filter((_, index) => columnVisibility[index]);

  function showColumnPicker() {
    // You can either set it by setting a property to show it
    if (props.showColumnPicker === true) {
      return true;
    }

    if (showHeader) {
      return fullColumns.some(function (c) {
        return c.show === false;
      });
    }

    return false;
  }

  const transformCsvData = (
    data: Array<T>,
    columns: DataTableCsvColumn<T>[],
  ) => {
    return data.map((row) => {
      const newRow: CsvRow = {};
      columns.forEach((column, index) => {
        newRow[column.title] = column.render(row, index);
      });
      return newRow;
    });
  };

  return (
    <div style={{ marginTop: "-15px", width: "100%" }}>
      <HorizontalStack align="spread" width="100%" verticalAlign="middle">
        {showColumnPicker() && (
          <ShowHideColumnsPopover
            columnVisibility={columnVisibility}
            columns={fullColumns}
            onChange={(index, show) => {
              setColumnVisibility((prev) =>
                prev.map((v, i) => (i === index ? show : v)),
              );
            }}
          >
            Show/Hide Columns (
            {columnVisibility.filter((visible) => !visible).length} hidden)
          </ShowHideColumnsPopover>
        )}
      </HorizontalStack>
      <Stack align="left" width="100%">
        <Table
          style={{ width: "100%", ...props.style }}
          pagination={props.pagination}
          columns={safeColumns<T>(
            columns.map(function (col) {
              return {
                title: (
                  <Stack align="left" key={col.title as string}>
                    <div>{col.title}</div>
                    {col.subtitle && (
                      <div style={{ color: Colors.LightText, fontSize: "7px" }}>
                        {col.subtitle}
                      </div>
                    )}
                  </Stack>
                ),
                ellipsis: col.ellipsis,
                dataIndex: "",
                key: col.title as string,
                render: (_, item, index) => col.render(item, index),
                sorter: col.sorter,
                defaultSortOrder: "descend",
                sortDirections: ["descend", "ascend", "descend"],
                width: col.width,
                fixed: col.fixed,
              };
            }),
          )}
          dataSource={props.data}
          rowKey={props.rowKey}
          rowSelection={props.rowSelection}
          scroll={props.scroll}
          showHeader={showHeader}
        />

        {props.csvColumns && (
          <HorizontalStack>
            <Spacer width={32} />
            <CSVLink
              data={transformCsvData(props.data, props.csvColumns)}
              headers={props.csvColumns.map((column) => ({
                label: column.title,
                key: column.title,
              }))}
            >
              Download CSV
            </CSVLink>
          </HorizontalStack>
        )}
      </Stack>
    </div>
  );
}
