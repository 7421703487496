import {
  CustomerInvoice,
  CustomerInvoiceState,
  CustomerInvoiceType,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, Form, Modal, message } from "antd";
import { useState } from "react";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import { CurrencyInput } from "../../Components/CurrencyInput";
import { TabProps } from "./TabProps";
import { useDisplay } from "../../Hooks/useDisplay";
import Spacer from "../../Spacer";
import HorizontalStack from "../../Components/HorizontalStack";
import TextArea from "antd/es/input/TextArea";
import { isBlank } from "@freightsimple/shared";

interface PartialCustomerRefundButtonProps extends TabProps {
  customerInvoice: CustomerInvoice;
}

export function PartialCustomerRefundButton(
  props: PartialCustomerRefundButtonProps,
) {
  const createCustomerInvoiceApi = useCustomerInvoiceApi();
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!;
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState("");
  const display = useDisplay();

  async function handleOk() {
    if (isBlank(reason)) {
      message.warning("You need to describe a reason!");
      return;
    }
    if (amount <= 0) {
      message.warning("You need to refund an amount greater than 0!");
      return;
    }
    if (props.customerInvoice.amount) {
      if (amount > props.customerInvoice.amount) {
        message.warning(
          "You cannot refund an amount greater than the invoice amount!",
        );
        return;
      }
    }
    setIsLoading(true);
    const customerInvoiceApi = await createCustomerInvoiceApi();
    try {
      await customerInvoiceApi.reduceOrRefundPartialInvoice({
        customerInvoiceId,
        amount,
        reason,
      });
      await props.onRefresh();
      message.success(`Refund processed`);
      display.hide();
      setAmount(0);
    } catch (e: any) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
    setIsLoading(false);
  }

  const enabled =
    props.customerInvoice.customerInvoiceState ===
      CustomerInvoiceState.Settled ||
    props.customerInvoice.customerInvoiceState ===
      CustomerInvoiceState.Reconciled;

  const isRefundable =
    props.customerInvoice.invoiceType === CustomerInvoiceType.ShipmentCharge ||
    props.customerInvoice.invoiceType === CustomerInvoiceType.AdditionalCharge;

  if (!enabled || !isRefundable) {
    return <></>;
  }

  return (
    <>
      <Modal
        title="Partial Refund Customer Invoice"
        open={display.status}
        onOk={handleOk}
        onCancel={display.hide}
        width={400}
        confirmLoading={isLoading}
        destroyOnClose
      >
        <p>How much do you want to refund the customer for this invoice?</p>
        <CurrencyInput
          amount={amount}
          setAmount={setAmount}
          currency={props.shipmentData.shipment.company.currency!}
        />
        <Spacer height={10} />
        <HorizontalStack>
          <Form.Item label="Customer Visible Reason">
            <TextArea
              cols={55}
              value={reason}
              onChange={(event) => setReason(event.target.value)}
            />
          </Form.Item>
        </HorizontalStack>
      </Modal>
      <Button onClick={display.show}>Partial Refund</Button>
    </>
  );
}
