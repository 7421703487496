import { CarrierServicePair } from "@freightsimple/generated-apollo-openapi-client";
import { isBlank } from "@freightsimple/shared";
import { isDateInThePast } from "../../Helpers/isDateInThePast";

interface AddQuoteGroupButtonProps {
  pickupDate: string;
  quoteGroupName: string;
  allCarriers: boolean;
  selectedCarriers: CarrierServicePair[];
  pickupLocationPostalCode: string | undefined;
  deliveryLocationPostalCode: string | undefined;
  lineItemsLength: number;
  addInsuranceToShipment: boolean;
  insuranceAmount: number;
}

export function errorMessagesForAddQuoteGroupButton(
  param: AddQuoteGroupButtonProps,
) {
  const {
    pickupDate,
    quoteGroupName,
    allCarriers,
    selectedCarriers,
    pickupLocationPostalCode,
    deliveryLocationPostalCode,
    lineItemsLength,
    addInsuranceToShipment,
    insuranceAmount,
  } = param;

  const usPostalCodePattern = /^(?:\d{5}|\d{5}-\d{4})$/;

  const canadianPostalCodePattern = /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/;

  const pickupDateIsInThePast = isDateInThePast(pickupDate);
  if (pickupDateIsInThePast) {
    return "Pickup Date is in the past";
  }

  if (isBlank(quoteGroupName)) {
    if (quoteGroupName === "" || quoteGroupName === undefined) {
      return "Quote Group Name should not be empty";
    }
  }

  if (
    allCarriers === false &&
    (selectedCarriers.length === 0 || selectedCarriers === undefined)
  ) {
    return "Please select at least one carrier";
  }

  if (addInsuranceToShipment && insuranceAmount < 1) {
    return "Insurance Amount has to be greater than zero";
  }

  if (isBlank(pickupLocationPostalCode)) {
    if (
      pickupLocationPostalCode === "" ||
      pickupLocationPostalCode === undefined
    ) {
      return "Pickup location postal code should not be empty";
    }
  }

  if (isBlank(deliveryLocationPostalCode)) {
    if (
      deliveryLocationPostalCode === "" ||
      deliveryLocationPostalCode === undefined
    ) {
      return "Delivery location postal code should not be empty";
    }
  }

  if (
    pickupLocationPostalCode &&
    !usPostalCodePattern.test(pickupLocationPostalCode) &&
    pickupLocationPostalCode &&
    !canadianPostalCodePattern.test(pickupLocationPostalCode)
  ) {
    return "Invalid pickup location postal code";
  }

  if (
    deliveryLocationPostalCode &&
    !usPostalCodePattern.test(deliveryLocationPostalCode) &&
    deliveryLocationPostalCode &&
    !canadianPostalCodePattern.test(deliveryLocationPostalCode)
  ) {
    return "Invalid delivery location postal code";
  }

  if (lineItemsLength < 1) {
    return "Please enter at least one line item";
  }
}
