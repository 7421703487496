import {
  ApolloMapCompany,
  ApolloMapQuery,
  ShipmentReport,
} from "@freightsimple/generated-apollo-openapi-client";
import HorizontalStack from "../../Components/HorizontalStack";
import { AllShipmentsScreenActiveFilters } from "./AllShipmentsScreenActiveFilters";
import { AllShipmentsScreenFilterButton } from "./AllShipmentsScreenFilterButton";
import { AllShipmentsScreenViewToggle } from "./AllShipmentsScreenViewToggle";
import { AllShipmentsScreenViewType } from "./AllShipmentsScreenViewType";

interface AllShipmentsScreenHeaderProps {
  shipments: ShipmentReport[];
  currentView: AllShipmentsScreenViewType;
  setCurrentView: (view: AllShipmentsScreenViewType) => void;
  query: ApolloMapQuery;
  setQuery: (_: ApolloMapQuery) => void;
  loading: boolean;
  companies: ApolloMapCompany[] | undefined;
}

export function AllShipmentsScreenHeader(props: AllShipmentsScreenHeaderProps) {
  const { loading } = props;
  return (
    <HorizontalStack
      align="spread"
      style={{
        background: "#fff",
        padding: "4px 8px",
        borderBottom: "1px solid #f0f0f0",
        width: "100%",
        // Disable pointer events when loading
        pointerEvents: loading ? "none" : "auto",
        opacity: loading ? 0.5 : 1,
      }}
    >
      <HorizontalStack align="spread" width="100%">
        <AllShipmentsScreenFilterButton
          shipments={props.shipments}
          query={props.query}
          setQuery={props.setQuery}
        />

        <AllShipmentsScreenActiveFilters
          query={props.query}
          companies={props.companies}
          setQuery={props.setQuery}
        />

        <AllShipmentsScreenViewToggle
          currentView={props.currentView}
          setCurrentView={props.setCurrentView}
        />
      </HorizontalStack>
    </HorizontalStack>
  );
}
