import {
  InvoiceAuditProblemFault,
  InvoiceAuditProblemResolutionState,
  InvoiceAuditProblemResolutionType,
  InvoiceAuditProblemType,
} from "@freightsimple/generated-apollo-openapi-client";
import InvoiceAuditProblemTypes from "../GeneratedFixtures/InvoiceAuditProblemTypes.json";
import { assertNever } from "./assertNever";

export function describeInvoiceAuditProblemType(
  type: InvoiceAuditProblemType,
): string {
  const name = InvoiceAuditProblemTypes.find((t) => t.type == type)?.name;
  if (!name) {
    console.error(`InvoiceAuditProblemType not found for ${type}`);
    return type.toString();
  }
  return name;
}

export function describeInvoiceAuditResolutionState(
  state: InvoiceAuditProblemResolutionState,
): string {
  switch (state) {
    case "pending":
      return "Pending";
    case "unresolved":
      return "Unresolved";
    case "resolved":
      return "Resolved";
    default:
      assertNever(state);
  }
}

export function describeInvoiceAuditResolutionType(
  value: InvoiceAuditProblemResolutionType,
): string {
  switch (value) {
    case "carrier_correction":
      return "Carrier Correction";
    case "additional_charge_issued":
      return "Additional Charge Issued";
    case "freightsimple_absorbed_cost":
      return "FreightSimple Absorbed Cost";
    case "minor":
      return "Minor";
    default:
      assertNever(value);
  }
}

export function describeInvoiceAuditProblemFault(
  value: InvoiceAuditProblemFault,
): string {
  switch (value) {
    case "customer":
      return "Customer";
    case "carrier":
      return "Carrier";
    case "freightsimple":
      return "FreightSimple";
    case "unknown":
      return "Unknown";
    default:
      assertNever(value);
  }
}
