import { Select } from "antd";
import Colors from "../../Components/Colors";
import Stack from "../../Components/Stack";
import {
  describeLocationType,
  getDistributionWareHouseDisplayName,
  getLocationTypes,
  nameLocationType,
} from "../../Helpers/locationTypes";
import { SelectProps } from "antd/lib";

import distributionWarehouseBrands from "../../GeneratedFixtures/DistributionWarehouseBrands.json";

interface LocationTypeDropdownProps {
  locationType: string | undefined;
  distributionWarehouseBrand?: string | undefined | null;
  setLocationType: (_: string) => void;
  setDistributionWarehouseBrand: (_: string) => void;
  allowDistributionWarehouseBrandClear?: SelectProps["allowClear"];
  allowLocationTypeClear?: SelectProps["allowClear"];
}
interface MainLocationTypeDropdownProps {
  locationType: string | undefined;
  setLocationType: (_: string) => void;
  allowClear?: SelectProps["allowClear"];
}

interface DistributionWareHouseBrandsDropDownProps {
  distributionWarehouseBrand: string | undefined | null;
  setDistributionWarehouseBrand: (_: string) => void;
  allowClear?: SelectProps["allowClear"];
}

export function LocationTypeDropdown(props: LocationTypeDropdownProps) {
  return (
    <>
      <MainLocationTypeDropdown
        locationType={props.locationType}
        setLocationType={props.setLocationType}
        allowClear={props.allowLocationTypeClear}
      />

      {props.locationType == "DistributionWarehouse" && (
        <DistributionWareHouseBrandsDropDown
          distributionWarehouseBrand={props.distributionWarehouseBrand}
          setDistributionWarehouseBrand={props.setDistributionWarehouseBrand}
          allowClear={props.allowDistributionWarehouseBrandClear}
        />
      )}
    </>
  );
}

function MainLocationTypeDropdown(props: MainLocationTypeDropdownProps) {
  const filterOption = (input: string, option: any) => {
    const type = option?.value;
    if (!type) return false;

    const name = nameLocationType(type, undefined).toLowerCase();
    const description = describeLocationType(type).toLowerCase();
    const searchTerm = input.toLowerCase();

    return name.includes(searchTerm) || description.includes(searchTerm);
  };

  const options: SelectProps["options"] = getLocationTypes().map((type) => ({
    label: nameLocationType(type, undefined),
    value: type,
    type: type,
  }));

  return (
    <Select
      allowClear={props.allowClear}
      value={props.locationType}
      style={{ width: 450, marginBottom: 10 }}
      onChange={function (e) {
        props.setLocationType(e);
      }}
      showSearch
      filterOption={filterOption}
      options={options}
      optionRender={(o) => (
        <Stack align="left">
          <div>{nameLocationType(o.data.type, undefined)}</div>
          <div style={{ color: Colors.LightText, fontSize: "12px" }}>
            {describeLocationType(o.data.type)}
          </div>
        </Stack>
      )}
    />
  );
}

function DistributionWareHouseBrandsDropDown(
  props: DistributionWareHouseBrandsDropDownProps,
) {
  const filterDistributionWarehouseBrand = (input: string, option: any) => {
    const label = option?.label?.toLowerCase();
    const searchTerm = input.toLowerCase();
    return label.includes(searchTerm);
  };

  const distributionWarehouseBrandOptions: SelectProps["options"] =
    distributionWarehouseBrands.map((brand) => ({
      label: getDistributionWareHouseDisplayName(brand.identifier),
      value: brand.identifier,
    }));

  return (
    <Select
      allowClear={props.allowClear}
      value={props.distributionWarehouseBrand}
      placeholder="Warehouse Brand"
      style={{
        width: 200,
        borderColor: "red",
      }}
      onChange={function (e) {
        props.setDistributionWarehouseBrand(e);
      }}
      showSearch
      filterOption={filterDistributionWarehouseBrand}
      options={distributionWarehouseBrandOptions}
      optionRender={(o) => (
        <Stack align="left">
          <div>
            <div>{o.data.label}</div>
          </div>
        </Stack>
      )}
    />
  );
}
