import * as React from "react";
import Spacer from "../Spacer";
import HorizontalStack, { AlignOptions } from "./HorizontalStack";
import Stack from "./Stack";
import { WarningComponent } from "./WarningComponent";
import { dashboardBaseUrl } from "../Helpers/dashboardBaseUrl";
import { isNotBlank } from "@freightsimple/shared";
import { getCarrierDisplayNameFromCarrierIdentifier } from "../Helpers/serviceProperties";
import { Tooltip } from "antd";
import Colors from "./Colors";
import styled from "styled-components";

interface CarrierLogoProps {
  carrierIdentifier: string | undefined;
  brokeredCarrierIdentifier: string | undefined;
  width: number;
  height: number;
  align?: AlignOptions;
  notClickable?: boolean;
}

const OverlayHeader = styled.div`
  font-weight: 500;
  font-size: 2rem;
  color: black;
`;

const CarrierLogo: React.FC<CarrierLogoProps> = (props: CarrierLogoProps) => {
  const {
    carrierIdentifier,
    brokeredCarrierIdentifier,
    width,
    height,
    align,
    notClickable,
  } = props;

  if (!carrierIdentifier) {
    return (
      <div>
        <WarningComponent /> Missing Carrier!
      </div>
    );
  }

  const style: React.CSSProperties = {};
  if (notClickable) {
    style.pointerEvents = "none";
  }

  const tooltipTitle =
    isNotBlank(brokeredCarrierIdentifier) && carrierIdentifier
      ? `${getCarrierDisplayNameFromCarrierIdentifier(carrierIdentifier)} brokered by ${getCarrierDisplayNameFromCarrierIdentifier(brokeredCarrierIdentifier!)}`
      : carrierIdentifier
        ? getCarrierDisplayNameFromCarrierIdentifier(carrierIdentifier)
        : "";

  return (
    <HorizontalStack
      align={align || "center"}
      style={{ width, height }}
      verticalAlign="middle"
    >
      <a style={style} href={`/carrier?carrierIdentifier=${carrierIdentifier}`}>
        {isNotBlank(brokeredCarrierIdentifier) ? (
          <Stack align="center">
            <Tooltip
              placement="left"
              overlayStyle={{ minWidth: "432px" }}
              overlay={
                <Stack
                  align="center"
                  style={{
                    backgroundColor: "white",
                    color: Colors.NormalText,
                    padding: "32px",
                    maxWidth: "100%",
                  }}
                >
                  <OverlayHeader>{tooltipTitle}</OverlayHeader>
                </Stack>
              }
            >
              <img
                style={{ maxHeight: "35px", maxWidth: "25px" }}
                alt={carrierIdentifier}
                src={`${dashboardBaseUrl()}/carrierLogos/${carrierIdentifier}.png`}
              />
            </Tooltip>
            <Spacer height={3} />
            <Tooltip
              placement="left"
              overlayStyle={{ minWidth: "432px" }}
              overlay={
                <Stack
                  align="center"
                  style={{
                    backgroundColor: "white",
                    color: Colors.NormalText,
                    padding: "32px",
                    maxWidth: "100%",
                  }}
                >
                  <OverlayHeader>{brokeredCarrierIdentifier ? getCarrierDisplayNameFromCarrierIdentifier(brokeredCarrierIdentifier) : ""}</OverlayHeader>
                </Stack>
              }
            >
              <img
                style={{ maxHeight: `${height}px`, maxWidth: `${width}px` }}
                alt={brokeredCarrierIdentifier}
                src={`${dashboardBaseUrl()}/carrierLogos/${brokeredCarrierIdentifier}.png`}
              />
            </Tooltip>
          </Stack>
        ) : (
          <Tooltip
            placement="left"
            overlayStyle={{ minWidth: "432px" }}
            overlay={
              <Stack
                align="center"
                style={{
                  backgroundColor: "white",
                  color: Colors.NormalText,
                  padding: "32px",
                  maxWidth: "100%",
                }}
              >
                <OverlayHeader>{tooltipTitle}</OverlayHeader>
              </Stack>
            }
          >
            <img
              style={{ maxHeight: `${height}px`, maxWidth: `${width}px` }}
              alt={carrierIdentifier}
              src={`${dashboardBaseUrl()}/carrierLogos/${carrierIdentifier}.png`}
            />
          </Tooltip>
        )}
      </a>
    </HorizontalStack>
  );
};

export default CarrierLogo;
