import { Route, Routes } from "react-router-dom";
import { ActiveShipmentsScreen } from "../Screens/ActiveShipmentsScreen";
import { AdminLogsScreen } from "../Screens/AdminLogsScreen";
import { AllShipmentsScreen } from "../Screens/AllShipmentsScreen";
import { BookedVsQuotedReportScreen } from "../Screens/BookedVsQuotedReportScreen";
import { CarrierComparisonReportScreen } from "../Screens/CarrierComparisonReportScreen";
import { CarrierInvoicesScreen } from "../Screens/CarrierInvoicesScreen";
import { CarrierRequestAuditsScreen } from "../Screens/CarrierRequestAuditsScreen";
import { CarrierScreen } from "../Screens/CarrierScreen";
import { CarrierServicesScreen } from "../Screens/CarrierServicesScreen";
import { ClaudePromptsScreen } from "../Screens/ClaudePromptsScreen";
import { CustomerInvoicesScreen } from "../Screens/CustomerInvoicesScreen";
import { EmailTemplatesScreen } from "../Screens/EmailTemplatesScreen";
import { KpisScreen } from "../Screens/KpisScreen";
import { ListCompaniesScreen } from "../Screens/ListCompaniesScreen";
import { ListSalesCallsScreen } from "../Screens/ListSalesCallsScreen";
import { ListSalesContactsScreen } from "../Screens/ListSalesContactsScreen";
import { LookupScreen } from "../Screens/LookupScreen";
import { MismatchedCarrierInvoicesScreen } from "../Screens/MismatchedCarrierInvoicesScreen";
import { MismatchedCustomerInvoicesScreen } from "../Screens/MismatchedCustomerInvoicesScreen";
import { OpenInvoiceAuditsScreen } from "../Screens/OpenInvoiceAuditsScreen";
import { ProfitLossReportScreen } from "../Screens/ProfitLossReportScreen";
import { QuoteLogsByCarrierScreen } from "../Screens/QuoteLogsByCarrierScreen";
import { RecentManualQuotesScreen } from "../Screens/RecentManualQuotesScreen";
import { ShipmentsPerCarrierScreen } from "../Screens/ShipmentsPerCarrierScreen";
import { SignalsScreen } from "../Features/Technical/Signals/SignalsScreen";
import { StarredCompaniesScreen } from "../Screens/StarredCompaniesScreen";
import { StarredShipmentsScreen } from "../Screens/StarredShipmentsScreen";
import { TestingScreen } from "../Screens/TestingScreen";
import { TodaysBookingsScreen } from "../Screens/TodaysBookingsScreen";
import { UpcomingAmyShipmentTasksScreen } from "../Screens/UpcomingAmyShipmentTasksScreen";
import { ViewClaimsScreen } from "../Screens/ViewClaimsScreen";
import { ViewCompanyScreen } from "../Screens/ViewCompanyScreen";
import { ViewDocumentScreen } from "../Screens/ViewDocumentScreen";
import { ViewShipmentScreen } from "../Screens/ViewShipmentScreen";
import { RecentCompanySalesReportScreen } from "../Screens/ViewShipmentScreenComponents/RecentCompanySalesReportScreen";

export function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<ActiveShipmentsScreen />} />
      <Route path="/active-shipments" element={<ActiveShipmentsScreen />} />
      <Route
        path="/recent-manual-quotes"
        element={<RecentManualQuotesScreen />}
      />
      <Route path="/all-shipments" element={<AllShipmentsScreen />} />
      <Route path="/claims" element={<ViewClaimsScreen />} />

      <Route path="/view-shipment" element={<ViewShipmentScreen />} />
      <Route path="/list-companies" element={<ListCompaniesScreen />} />
      <Route
        path="/list-sales-contacts"
        element={<ListSalesContactsScreen />}
      />
      <Route path="/list-sales-calls" element={<ListSalesCallsScreen />} />
      <Route
        path="/booked-vs-quoted"
        element={<BookedVsQuotedReportScreen />}
      />

      <Route path="/carriers" element={<ShipmentsPerCarrierScreen />} />
      <Route path="/carriers/services" element={<CarrierServicesScreen />} />
      <Route path="/carrier" element={<CarrierScreen />} />
      <Route path="/view-company" element={<ViewCompanyScreen />} />
      <Route path="/view-document" element={<ViewDocumentScreen />} />
      <Route path="/profit-loss" element={<ProfitLossReportScreen />} />
      <Route path="/lookup" element={<LookupScreen />} />
      <Route path="/kpis" element={<KpisScreen />} />
      <Route
        path="/recent-company-sales-report"
        element={<RecentCompanySalesReportScreen />}
      />
      <Route path="/todays-bookings" element={<TodaysBookingsScreen />} />
      <Route
        path="/carrier-comparison-report"
        element={<CarrierComparisonReportScreen />}
      />

      <Route
        path="/carrier-request-audits"
        element={<CarrierRequestAuditsScreen />}
      />
      <Route
        path="/mismatched-carrier-invoices"
        element={<MismatchedCarrierInvoicesScreen />}
      />
      <Route path="/admin-logs" element={<AdminLogsScreen />} />
      <Route
        path="/quote-logs-by-carrier"
        element={<QuoteLogsByCarrierScreen />}
      />
      <Route path="/email-templates" element={<EmailTemplatesScreen />} />
      <Route path="/claude-prompts" element={<ClaudePromptsScreen />} />
      <Route
        path="/mismatched-customer-invoices"
        element={<MismatchedCustomerInvoicesScreen />}
      />
      <Route path="/carrier-invoices" element={<CarrierInvoicesScreen />} />
      <Route
        path="/open-invoice-audits"
        element={<OpenInvoiceAuditsScreen />}
      />
      <Route path="/customer-invoices" element={<CustomerInvoicesScreen />} />

      <Route path="/starred-shipments" element={<StarredShipmentsScreen />} />
      <Route path="/starred-companies" element={<StarredCompaniesScreen />} />

      <Route path="/signals" element={<SignalsScreen />} />
      <Route path="/testing" element={<TestingScreen />} />

      <Route
        path="/amy/upcoming-amy-shipment-tasks"
        element={<UpcomingAmyShipmentTasksScreen />}
      />
    </Routes>
  );
}
