import { InvoiceAuditProblemType } from "@freightsimple/generated-apollo-openapi-client";
import { Select, SelectProps } from "antd";
import Stack from "../../Components/Stack";
import InvoiceAuditProblemTypes from "../../GeneratedFixtures/InvoiceAuditProblemTypesGrouped.json";
import Colors from "../../Components/Colors";

interface InvoiceAuditProblemTypeDropdownProps {
  value: InvoiceAuditProblemType | undefined;
  setValue: (
    _: InvoiceAuditProblemType | undefined,
  ) => void;
  mode?: SelectProps["mode"];
  placeHolder?: string;
}

export function InvoiceAuditProblemTypeDropdown(
  props: InvoiceAuditProblemTypeDropdownProps,
) {
  const options: SelectProps["options"] = InvoiceAuditProblemTypes.map(
    (group) => ({
      label: group.name,
      title: group.name,
      options: group.items.map((item) => ({
        label: item.name,
        value: item.type,
        ...item,
      })),
    }),
  );

  return (
    <Select
      mode={props.mode}
      showSearch
      value={props.value}
      onChange={function (e) {
        props.setValue(e);
      }}
      style={{ width: "500px" }}
      placeholder={props.placeHolder ?? "No problem selected"}
      allowClear
      options={options}
      optionRender={(option) => (
        <Stack align="left">
          <div>{option.data.name}</div>
          <div style={{ fontSize: 11, color: Colors.LightText }}>
            {option.data.description}
          </div>
        </Stack>
      )}
    />
  );
}
