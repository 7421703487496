import {
  ApolloMapCompany,
  ApolloMapQuery,
  ApolloMapQueryBookingStatus,
  DetailedShipmentReport,
} from "@freightsimple/generated-apollo-openapi-client";

import { Popover, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useMapsApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import Colors from "../Components/Colors";
import HorizontalStack from "../Components/HorizontalStack";
import Stack from "../Components/Stack";
import { useOnce } from "../Hooks/useOnce";
import Spacer from "../Spacer";

import { AllShipmentsScreenCalendarTab } from "./AllShipmentComponents/AllShipmentsScreenCalendarTab";
import { AllShipmentsScreenHeader } from "./AllShipmentComponents/AllShipmentsScreenHeader";
import { AllShipmentsScreenListTab } from "./AllShipmentComponents/AllShipmentsScreenListTab";
import { AllShipmentsScreenMapTab } from "./AllShipmentComponents/AllShipmentsScreenMapTab";
import { AllShipmentsScreenQueryViewer } from "./AllShipmentComponents/AllShipmentsScreenQueryViewer";
import { AllShipmentsScreenStats } from "./AllShipmentComponents/AllShipmentsScreenStats";
import { useGeneralQuoteFilter } from "./AllShipmentComponents/Helpers/quoteFilter";
import dayjs from "dayjs";
import { AllShipmentsScreenViewType } from "./AllShipmentComponents/AllShipmentsScreenViewType";
import { OpenShipmentsInNewTabsButton } from "./AllShipmentComponents/OpenShipmentsInNewTabsButton";
import { DownloadPricingReportButton } from "../Components/DownloadPricingReportLink";
import { useParamState } from "../Hooks/useParamState";

export function AllShipmentsScreen() {
  const [currentView, setCurrentView] = useState<AllShipmentsScreenViewType>(
    AllShipmentsScreenViewType.List,
  );

  const [query, setQuery, serializedQuery] = useParamState<ApolloMapQuery>({
    key: "q",
    showDefaultValueInURL: true,
    defaultValue: {
      freeTextQuery: "",
      bookingStatus: ApolloMapQueryBookingStatus.Any,
      startDate: dayjs().subtract(30, "days").format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
    },
  });

  const createMapsApi = useMapsApi();

  const [companies, setCompanies] = useState<ApolloMapCompany[] | undefined>();

  useOnce(async function () {
    const mapsApi = await createMapsApi();
    const response = await mapsApi.getCompanies();
    setCompanies(response);
  });

  const [loading, setLoading] = useState(false);

  const [unfiltered, setUnfilteredShipments] = useState<
    DetailedShipmentReport[]
  >([]);
  const quoteFilter = useGeneralQuoteFilter(query.quoteFilter);

  const shipments = useMemo(
    function () {
      return unfiltered.filter(quoteFilter);
    },
    [unfiltered, quoteFilter],
  );

  async function load() {
    setLoading(true);
    const mapsApi = await createMapsApi();
    const response = await mapsApi.generateMap({ apolloMapQuery: query });
    setUnfilteredShipments(response);
    setLoading(false);
  }

  useEffect(() => {
    load();
  }, [serializedQuery]);

  return (
    <Stack align="left" style={{ width: "100%" }}>
      <AllShipmentsScreenHeader
        currentView={currentView}
        setCurrentView={setCurrentView}
        query={query}
        setQuery={setQuery}
        shipments={shipments}
        loading={loading}
        companies={companies}
      />
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(255, 255, 255, 0.6)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <Spin size="large" />
        </div>
      )}
      <HorizontalStack
        align="right"
        style={{ width: "100%", paddingRight: "4px", marginTop: "4px" }}
      >
        <AllShipmentsScreenStats shipments={shipments} query={query} />
        <Spacer width={8} />
        <ButtonRow>
          <Popover
            content={
              <Stack align="left" style={{ marginLeft: "-16px" }}>
                <DownloadPricingReportButton quotes={shipments} />
              </Stack>
            }
            title="Available Reports"
            trigger="hover"
            placement="left"
          >
            <span
              style={{
                color: Colors.Blue,
                cursor: "pointer",
                fontSize: "11px",
                fontWeight: 600,
              }}
            >
              Available Reports
            </span>
          </Popover>
          <Spacer width={8} />
          <AllShipmentsScreenQueryViewer query={query} />
          <Spacer width={8} />
          <OpenShipmentsInNewTabsButton shipments={shipments} />
        </ButtonRow>
      </HorizontalStack>
      {currentView === AllShipmentsScreenViewType.List && (
        <AllShipmentsScreenListTab shipments={shipments} />
      )}
      {currentView === AllShipmentsScreenViewType.Map && (
        <AllShipmentsScreenMapTab query={query} shipments={shipments} />
      )}
      {currentView === AllShipmentsScreenViewType.Calendar && (
        <AllShipmentsScreenCalendarTab query={query} shipments={shipments} />
      )}
    </Stack>
  );
}
