import { CompanyTabProps } from "../ViewCompanyScreen";
import { TabProps } from "./TabProps";

export enum ClaudeConversationType {
  SHIPMENT = "SHIPMENT",
  COMPANY = "COMPANY",
}

interface ClaudeButtonProps {
  context: ClaudeConversationType;
}

export type CombinedProps =
  | (ClaudeButtonProps &
      TabProps & { context: ClaudeConversationType.SHIPMENT })
  | (ClaudeButtonProps &
      CompanyTabProps & { context: ClaudeConversationType.COMPANY });

export function getClaudeConversationIds(
  context: ClaudeConversationType,
  props: CombinedProps,
) {
  let shipmentId: string | null, companyId: string | null, chatTitle: string;

  switch (context) {
    case ClaudeConversationType.SHIPMENT: {
      const shipmentProps = props as Extract<
        CombinedProps,
        { context: ClaudeConversationType.SHIPMENT }
      >;

      shipmentId = shipmentProps.shipmentData?.shipmentId ?? null;
      chatTitle = "Shipment";
      companyId = null;
      break;
    }
    case ClaudeConversationType.COMPANY: {
      const companyProps = props as Extract<
        CombinedProps,
        { context: ClaudeConversationType.COMPANY }
      >;

      companyId = companyProps.data?.company.companyId ?? null;
      chatTitle = "Company";
      shipmentId = null;
      break;
    }
    default: {
      shipmentId = null;
      companyId = null;
      chatTitle = "";
      break;
    }
  }

  return { shipmentId, companyId, chatTitle };
}
