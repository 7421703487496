import {
  AdminLogsApi,
  AmyApi,
  CalendarStatsApi,
  CarrierInvoiceApi,
  CarrierInvoiceAuditApi,
  CarriersApi,
  ClaudeApi,
  CompaniesApi,
  Configuration,
  CustomerInvoiceApi,
  DocumentsApi,
  FreightClaimsApi,
  LookupApi,
  MapApi,
  ProfitLossApi,
  QuotesApi,
  ShipmentsApi,
  SignalsApi,
  StatsApi,
  SupportEmailApi,
  TechnicalApi,
} from "@freightsimple/generated-apollo-openapi-client";

import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { getConfig, isAuthenticationDisabled } from "../environment";
import { loginRequest } from "../authConfig";

import { ActivitiesApi } from "@freightsimple/generated-apollo-openapi-client";
import { v4 as uuidv4 } from "uuid";

// Format: version(2)-trace-id(32)-parent-id(16)-flags(2)
function generateW3CTraceParent(): string {
  const version = "00";
  const traceId = uuidv4().replace(/-/g, "");
  const spanId = uuidv4().replace(/-/g, "").slice(0, 16);
  const flags = "01"; // Sampled

  return `${version}-${traceId}-${spanId}-${flags}`;
}

function useApi<T>(
  creator: (configuration: Configuration) => T,
): () => Promise<T> {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  // This is basically for working totally offline
  // Authentication requires making calls to Microsoft/Azure/AD
  // If you're on a flight, say, this isn't always possible
  // You would also need to start up a local version of the backend that doesn't check authentication
  // SKIP_LOCAL_APOLLO_AUTHENTICATION needs to be set on the server
  async function createApiWithoutAuthentication() {
    const api = creator(
      new Configuration({
        ...getConfig(),
      }),
    );
    return api;
  }

  if (isAuthenticationDisabled()) {
    return async function () {
      return createApiWithoutAuthentication();
    };
  }

  if (account === null) {
    throw new Error("Should not be here with no account");
  }

  async function getToken(
    account: AccountInfo,
    instance: IPublicClientApplication,
  ) {
    try {
      const response = await instance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: account,
      });

      return response.accessToken;
    } catch (e) {
      console.warn({ e });
      await instance.loginRedirect(loginRequest);
    }
  }

  async function createApi(
    account: AccountInfo,
    instance: IPublicClientApplication,
  ) {
    const accessToken = await getToken(account, instance);

    const baseConfig = new Configuration({
      ...getConfig(),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      middleware: [
        {
          pre: async (context: any) => {
            const traceParent = generateW3CTraceParent();
            // Using standard W3C trace context header names
            context.init.headers["traceparent"] = traceParent;
            return context;
          },
        },
      ],
    });

    return creator(baseConfig);
  }

  return async function () {
    return createApi(account, instance);
  };
}

export function useShipmentsApi(): () => Promise<ShipmentsApi> {
  return useApi(function (configuration) {
    return new ShipmentsApi(configuration);
  });
}

export function useQuotesApi(): () => Promise<QuotesApi> {
  return useApi(function (configuration) {
    return new QuotesApi(configuration);
  });
}

export function useCompaniesApi(): () => Promise<CompaniesApi> {
  return useApi(function (configuration) {
    return new CompaniesApi(configuration);
  });
}

export function useStatsApi(): () => Promise<StatsApi> {
  return useApi(function (configuration) {
    return new StatsApi(configuration);
  });
}

export function useDocumentsApi(): () => Promise<DocumentsApi> {
  return useApi(function (configuration) {
    return new DocumentsApi(configuration);
  });
}

export function useCalendarStatsApi(): () => Promise<CalendarStatsApi> {
  return useApi(function (configuration) {
    return new CalendarStatsApi(configuration);
  });
}

export function useMapsApi(): () => Promise<MapApi> {
  return useApi(function (configuration) {
    return new MapApi(configuration);
  });
}

export function useLookupApi(): () => Promise<LookupApi> {
  return useApi(function (configuration) {
    return new LookupApi(configuration);
  });
}

export function useCustomerInvoiceApi(): () => Promise<CustomerInvoiceApi> {
  return useApi(function (configuration) {
    return new CustomerInvoiceApi(configuration);
  });
}

export function useCarriersApi(): () => Promise<CarriersApi> {
  return useApi(function (configuration) {
    return new CarriersApi(configuration);
  });
}

export function useCarrierInvoiceApi(): () => Promise<CarrierInvoiceApi> {
  return useApi(function (configuration) {
    return new CarrierInvoiceApi(configuration);
  });
}

export function useCarrierInvoiceAuditApi(): () => Promise<CarrierInvoiceAuditApi> {
  return useApi(function (configuration) {
    return new CarrierInvoiceAuditApi(configuration);
  });
}

export function useAdminLogsApi(): () => Promise<AdminLogsApi> {
  return useApi(function (configuration) {
    return new AdminLogsApi(configuration);
  });
}

export function useProfitLossApi(): () => Promise<ProfitLossApi> {
  return useApi(function (configuration) {
    return new ProfitLossApi(configuration);
  });
}

export function useAmyApi(): () => Promise<AmyApi> {
  return useApi(function (configuration) {
    return new AmyApi(configuration);
  });
}

export function useSignalsApi(): () => Promise<SignalsApi> {
  return useApi(function (configuration) {
    return new SignalsApi(configuration);
  });
}

export function useTechnicalApi(): () => Promise<TechnicalApi> {
  return useApi(function (configuration) {
    return new TechnicalApi(configuration);
  });
}

export function useSupportEmailApi(): () => Promise<SupportEmailApi> {
  return useApi(function (configuration) {
    return new SupportEmailApi(configuration);
  });
}

export function useFreightClaimsApi(): () => Promise<FreightClaimsApi> {
  return useApi(function (configuration) {
    return new FreightClaimsApi(configuration);
  });
}

export function useActivitiesApi(): () => Promise<ActivitiesApi> {
  return useApi(function (configuration) {
    return new ActivitiesApi(configuration);
  });
}

export function useClaudeApi(): () => Promise<ClaudeApi> {
  return useApi(function (configuration) {
    return new ClaudeApi(configuration);
  });
}
