import {
  CountryCode,
  CustomsDocsState,
} from "@freightsimple/generated-apollo-openapi-client";
import { message, Tag } from "antd";
import { useShipmentsApi } from "../../Apis/Apis";
import { ConfirmButton } from "../../Components/ConfirmButton";
import HorizontalStack from "../../Components/HorizontalStack";
import { KeyValues } from "../../Components/KeyValues";
import Spacer from "../../Spacer";
import { TabProps } from "./TabProps";
import { assertNever } from "../../Helpers/assertNever";
import { CreatedAt } from "../../Components/CreatedAt";
import { WithClipboardLink } from "../../Components/WithClipboardLink";
import { ReactNode } from "react";
import { WarningComponent } from "../../Components/WarningComponent";

interface CustomsDocsStateTagProps {
  state: CustomsDocsState;
}

function CustomsDocsStateTag(props: CustomsDocsStateTagProps) {
  const state = props.state;
  switch (state) {
    case CustomsDocsState.NotRequired:
      return <Tag color="default">Not Required</Tag>;
    case CustomsDocsState.Missing:
      return <Tag color="yellow">‼️ Missing</Tag>;
    case CustomsDocsState.Uploaded:
      return <Tag color="error">🕕 Uploaded</Tag>;
    case CustomsDocsState.SentToCarrier:
      return <Tag color="green">✅ Sent To Carrier</Tag>;
    case CustomsDocsState.Acknowledged:
      return <Tag color="blue">Acknowledged</Tag>;
    default:
      assertNever(state);
  }
}

interface CustomsDocsReuploadButtonProps extends TabProps {
  state: CustomsDocsState;
}

function CustomsDocsReuploadButton(props: CustomsDocsReuploadButtonProps) {
  const state = props.state;
  const createShipmentApi = useShipmentsApi();
  const shipmentId = props.shipmentData.shipmentId;

  async function onConfirm() {
    const shipmentApi = await createShipmentApi();

    try {
      await shipmentApi.clearCustomsUploadState({ shipmentId });
      await props.onRefresh();
      message.success(
        "Customs docs state reset. You can now tell the customer they can upload the docs again",
      );
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
  }

  if (
    state !== CustomsDocsState.Missing &&
    state !== CustomsDocsState.NotRequired
  ) {
    return (
      <ConfirmButton
        type="link"
        question="Are you sure you want to reset customs upload status? This will allow a customer to upload the customs docs again"
        okText="Yes, reset"
        tooltip="This will reset customs docs status, so they can be uploaded again"
        onConfirm={onConfirm}
      >
        Reset upload status
      </ConfirmButton>
    );
  } else {
    return <></>;
  }
}

type KeyValuePair = {
  key: ReactNode;
  value: ReactNode;
};

export function CrossBorderInfo(props: TabProps) {
  const data: KeyValuePair[] = [];

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const address = props.shipmentData.shipment.shipment.broker?.address!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const contact = props.shipmentData.shipment.shipment.broker?.contact!;

  if (props.shipmentData.shipment.shipment.bookedAt !== undefined) {
    data.push(
      {
        key: <strong>Docs Upload Status</strong>,
        value: (
          <HorizontalStack>
            <CustomsDocsStateTag
              state={props.shipmentData.shipment.shipment.customsDocsState!}
            />
            <Spacer width={4} />
            <CustomsDocsReuploadButton
              state={props.shipmentData.shipment.shipment.customsDocsState!}
              {...props}
            />
          </HorizontalStack>
        ),
      },
      {
        key: <strong>Customs Docs Delivered At</strong>,
        value: (
          <CreatedAt
            timestamp={
              props.shipmentData.shipment.shipment.customDocsDeliveredAt
            }
          />
        ),
      },
      {
        key: <strong>Customs Docs Sent To</strong>,
        value: (
          <div>{props.shipmentData.shipment.shipment.customDocsSentTo}</div>
        ),
      },
      {
        key: (
          <div>
            <strong>Broker Business Name</strong>
            <br />
            <div
              style={{
                fontWeight: 500,
                color: "gray",
              }}
            >
              <WarningComponent /> Self-Clearance not allowed.
            </div>
          </div>
        ),
        value: (
          <WithClipboardLink>
            {`${props.shipmentData.shipment.shipment.broker?.businessName}`}
          </WithClipboardLink>
        ),
      },
      {
        key: <strong>Broker Address</strong>,
        value: (
          <WithClipboardLink>
            {address.addressLine!} {address.addressLine2!}
            {`${address.city}, ${address.stateOrProvinceCode}`}
            {address.postalCode!}
            {address.countryCode! === CountryCode.Ca
              ? "Canada"
              : "United States"}
          </WithClipboardLink>
        ),
      },
      {
        key: <strong>Broker Contact</strong>,
        value: (
          <WithClipboardLink>
            {contact.contactName!}
            {`${contact.phoneNumber!} Ext. ${contact.phoneNumberExtension}`}
          </WithClipboardLink>
        ),
      },
      {
        key: <strong>Broker Email</strong>,
        value: <WithClipboardLink>{contact.emailAddress!}</WithClipboardLink>,
      },
    );
  }
  return <KeyValues data={data} />;
}
